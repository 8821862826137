import React, { useState } from "react";
import { Modal } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { createRateCardForm } from "../../store/rates/createRateCardSlice";
import Select from "../../components/UI/forms/Select";
import Input from "../../components/UI/forms/InputFloatingLabel";
import TextArea from "../../components/UI/forms/TextArea";
import { useRequest } from "../../hooks/useRequest";
import { IContact } from "../../models/ContactModel";
import { ContactTypeEx } from "../../util/ContactsFunctions";
import { StylesConfig } from "react-select";
import user from "../../assets/icons/user.svg";
import { RateCardService } from "../../services/rates";
import { useToast } from "../../hooks/useToast";
import { UploadError } from "../../util";
import { AxiosResponse } from "axios";
import { ApiError } from "../../models";

export type RateCardModalType = "clients" | "system";

type Props = {
  onClose: (value?: boolean) => void;
  onSave: (type?: string, name?: string, to_id?: string) => void;
  viewCreateRate?: boolean;
  setViewCreateRate?: any;
  setViewModal?: any;
  savingRate?: boolean;
  modalConfirm?: boolean;
  setModalConfirm?: any;
  setSavingRate?: any;
  newCreateRateSend?: any;
  setModalBrief?: any;
  systemRateCardId: string;
  projectDataId?: any;
  setModalNewQuotes?: any;
  secondMessage?: any;
  setSecoundMessage?: any;
  body?: React.ReactNode;
  type?: RateCardModalType;
} & (
  | {
      confirmText: string;
      cancelText: string;
      buttons?: undefined;
    }
  | {
      buttons?: React.ReactNode;
      confirmText?: undefined;
      cancelText?: undefined;
    }
);

const AddRateCardModal: React.FC<Props> = ({
  onClose,
  onSave,
  systemRateCardId,
  type,
  buttons,
  cancelText,
  confirmText,
  body,
  viewCreateRate,
  setViewCreateRate,
  setViewModal,
  newCreateRateSend,
  savingRate,
  setSavingRate,
  modalConfirm,
  setModalConfirm,
  secondMessage,
  setSecoundMessage,
  setModalBrief,
  setModalNewQuotes,
  projectDataId,
}) => {
  const dispatch = useDispatch();
  const { data: clients } = useRequest<IContact[]>(
    type === "clients" ? { url: "blackbook/contacts/all?type=CLIENT" } : null
  );

  const [name, setname] = useState<string>("");
  const [description, setdescription] = useState<string>("");
  const [clientId, setclientId] = useState<string>("");

  const reset = () => {
    setname("");
    setclientId("");
  };

  const sendData = {
    name: name,
    description: description,
    assigned_to: clientId,
    rate_card_items: newCreateRateSend,
  };

  const IdClientFromcreateQuote = projectDataId?.client?._id;

  console.log("sendData", sendData);
  console.log("IdClientFromcreateQuote", IdClientFromcreateQuote);
  console.log("clients", clients);

  const toast = useToast();

  const save = () => {
    if (viewCreateRate) {
      setViewCreateRate(false);
      setSavingRate(true);
      setViewModal(true);

      RateCardService.CreateRateCard(sendData)
        .then((res) => {
          if (res) {
            toast.success("Rate has been successfully create.");
            setSavingRate(false);
            setModalConfirm(true);
            setSecoundMessage(true);
            setdescription("");
            setclientId("");
            setSavingRate(false);
            // reset();
            // onClose();
            return;
          }
        })
        .catch((err: AxiosResponse<ApiError<UploadError[]>>) => {
          if (err?.data?.message) {
            setSavingRate(false);
            setViewCreateRate(true);
            setSavingRate(false);
            setViewModal(false);
            toast.error("An error occurred could not create rate.");
            return;
          }
        });
    } else {
      dispatch(
        createRateCardForm.setData({
          name: name,
          description: description,
          client_id: clientId,
        })
      );
      onSave(type);
      reset();
      onClose();
    }
  };

  const logo = (url = user) => ({
    ":before": {
      content: '" "',
      width: "29px",
      height: "29px",
      backgroundSize: "29px 29px",
      backgroundImage: `url(${encodeURI(url)})`,
      borderRadius: "50%",
      marginRight: "6px",
      display: "inline-block",
    },
  });

  const styles = {
    option: (data: any) => ({ fontSize: "14px", ...logo(data.url) }),
    placeholder: {...logo()},
    singleOption: (data: any) => ({...logo(data.url) }),
  };

  const closeModal = () => {
    onClose();
    setViewCreateRate(false);
    setModalNewQuotes(false);
    setSecoundMessage(false);
  };

  const selectClient = IdClientFromcreateQuote
    ? clients
        ?.filter((items, key) => items._id === IdClientFromcreateQuote)
        .map((item, key) => {
          return {
            value: item._id,
            label: ContactTypeEx[item.type].getTitle(item),
            url: ContactTypeEx[item.type].getLogo(item),
          };
        })
    : "";

  return (
    <Modal
      isOpen={true}
      toggle={onClose.bind(null, undefined)}
      centered
      style={{ maxWidth: "400px" }}
    >
      <div className="container">
        <div className="row p-4">
          <div className="col-12 text-center">
            <h2 className="text-secondary typo-display">
              <strong>
                Add {type === "system" ? "System" : "Client"} Rate Card
              </strong>
            </h2>
          </div>
          <div className="col-12 mt-3">
            <div className="">
              <div>
                <Input
                  value={name}
                  onChange={(value) => setname(value)}
                  placeHolder="Rate card name"
                  isRequired={true}
                />
              </div>
              <div>
                <TextArea
                  value={description}
                  onChange={(value) => setdescription(value)}
                  placeHolder="Description"
                  rows={3}
                />
              </div>
              {type === "clients" && (
                <div className="pb-3">
                  <Select
                    value={selectClient}
                    placeholder="Client"
                    onChange={setclientId}
                    styles={styles}
                    options={
                      clients
                        ? clients.map((item, key) => {
                            return {
                              value: item._id,
                              label: ContactTypeEx[item.type].getTitle(item),
                              url: ContactTypeEx[item.type].getLogo(item),
                            };
                          })
                        : []
                    }
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="row justify-content-evenly">
              <div className="d-flex justify-content-center">
                <button
                  disabled={
                    (type === "system" &&
                      (name === "" || description === "")) ||
                    (type === "clients" &&
                      (name === "" || description === "" || clientId === ""))
                  }
                  className="btn btn-success btn-lg text-white mx-1 px-4"
                  onClick={() => save()}
                >
                  SAVE
                </button>
                <button
                  className="btn btn-danger btn-lg text-white mx-1 px-4"
                  onClick={closeModal}
                >
                  CANCEL
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddRateCardModal;
