import InfoDisplay from '../../../../../UI/InfoDisplay';
import PhotoDisplay from '../../../../../UI/PhotoDisplay';
import QuickNotes from '../../../../../UI/QuickNotes';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { useEffect, useState } from 'react';
import AddComentsModal from 'src/components/Modals/AddComentsModal';
import { AllWareHouses, CreateCommentForAsset, DeleteCommentForAsset, UpdateCommentForAsset } from 'src/store/stockpile/stockpileSlice';
import { AddComment, deleteComment, GetAceesoriesbyID, updateComment } from 'src/store/stockpile/selectAssetsSlice';
import SimpleButton from 'src/components/UI/buttons/SimpleButton';
import { ConvertSize } from 'src/util/sizesConvert';
import moment from 'moment';
import { getCategoryName } from 'src/util/NameTypes';
import { CheckValue } from 'src/util/CheckValue';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import { useHistory, useParams } from 'react-router-dom';
import { useRequest } from 'src/hooks/useRequest';
import { useToast } from 'src/hooks/useToast';

import TrashIcon from '../../../../../../assets/icons/trash.svg'
import EditIcon from '../../../../../../assets/icons/edit.svg'
import { getProjectId, updateProject } from '../../../../../../store/factory/factorySlice';
import ModalStatus from '../../../tabs/modalStatus/ModalStatus';
import ModalBrief from '../../../tabs/modalBrief/ModalBrief';

interface Props {
    setDeleteJobModal:any;
    setjobIdDelete:any;
}

const ProjectDetail: React.FC<Props> = (props) => {
    const params = useParams<{ id: any; }>();
    const ASSET_DATA: any = useSelector((state: RootState) => state.selectAssetsSlice.data) || [];
    const COMMENTS_ASSETS: Array<object> = useSelector((state: RootState) => state.selectAssetsSlice.comments);
    const categories = useSelector((state: RootState) => state.categorieSlice.categories);
    const WareHouses: Array<any> = useSelector((state: RootState) => state.stockpileSlice.AllWareHouses);
    const Selected: any = useSelector((state: RootState) => state.RecursosVisualeSlice.selectedState);
    const [addModal, setAddModal] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [idForEdit, setIdForEdit] = useState('')
    const [view, setView] = useState<boolean>(false);
    const [client, setClient] = useState<any>([]);
    const [organization, setOrganization] = useState<any>([]);
    const [subCat, setSubCat] = useState('')
    const toast = useToast()
    const [jodIdDelete, setjobIdDelete] = useState<string | null>(null);
    const [deleteJobModal, setDeleteJobModal] = useState<boolean>(false);
    const [deleteQuoteModal, setDeleteQuoteModal] = useState<boolean>(false);
    const history = useHistory()
    const { projectDataId } = useSelector((state: RootState) => state.factorySlice);
    const [brief, setBrief] = useState<string>("");
    const [viewBrief, setViewBrief] = useState<boolean>(false);


    const dispatch = useDispatch()

    const [activeControl, setActiveControl] = useState<string>("");


    const { jobsData, loading, jobsDataId, QuotesId, QuoteVersion } = useSelector(
        (state: RootState) => state.getJobsTypeSlice
    );

    const { updateProjectStatus } = useSelector(
        (state: RootState) => state.factorySlice
    );


    const [sendProject, setSendProject] = useState({
        name: projectDataId?.name,
        start_date: moment(projectDataId?.start_date).format("MM/DD/YYYY"),
        end_date: moment(projectDataId?.end_date).format("MM/DD/YYYY"),
        status: projectDataId?.status,
        id: params.id,
    });



    useEffect(() => {
        //))
    }, [])

    const selectData = () => {
        [projectDataId && projectDataId].map((e: any) => {
            if (e?.client) {
                setClient(e.client.entity);
            }
            if (e?.organization) {
                setOrganization(e?.organization.entity);
            }
        });
    };
    useEffect(() => {
        setSendProject({ ...sendProject, name: projectDataId?.name });
    }, [sendProject.status]);

    useEffect(() => {
        setSendProject((prev) => ({
            ...prev,
            status: projectDataId?.status,
        }));
    }, [projectDataId]);

    const getNewStatus = () => {
        dispatch(updateProject(sendProject));
    };

    const selectedColor = (status: any) => {
        let select;
        //  if (item?.status) {
        if (status === "Approved") {
            select = "approved";
        } else if (status === "Proporsal") {
            select = "proporsal";
        } else if (status === "Active") {
            select = "active";
        } else if (status === "On Hold") {
            select = "onHold";
        } else if (status === "Lost") {
            select = "lost";
        } else if (status === "Complete") {
            select = "complete";
        }
        // }


        return select;
    };

    useEffect(() => {
        selectedColor(projectDataId?.status);
        selectData();
    }, [projectDataId, updateProjectStatus]);


    // 67807598690173953
    const getCategory = (id: any) => {
        setSubCat(getCategoryName(id, categories))
    }



    const getDataId = () => {
        dispatch(getProjectId(params.id));
    };

    useEffect(() => {
        getDataId();
    }, [params]);


    const tab = "\u00A0";


    const handleDeletedJob = (e: any, item: any) => {
        props.setDeleteJobModal(true);
        props.setjobIdDelete(item._id);
    };
    

    const editProject = (e, item) => {
        history.push(
            `/factory/edit/project/${params.id}/job/${item._id}`
        );
    };

    const viewBriefCLient = (brief: any) => {
        setBrief(brief.client_brief);
        setViewBrief(true);
        console.log("brief", brief.client_brief);
    };


    return (
        <div className="container-fluid p-0 m-0">

            <ModalBrief
                view={viewBrief}
                setView={setViewBrief}
                toggle={viewBrief}
                brief={brief}
            />

            <div className="row border-bottom p-0 py-3 gy-n5 px-3">


                <div className="col-6">
                    <InfoDisplay title="PROJECT NAME" texts={[{ text: CheckValue(projectDataId?.name) }]} />
                </div>

                <div className="col-6">
                    <InfoDisplay title="PROJECT START DATE" texts={[{
                        text: projectDataId?.name ? CheckValue(moment(projectDataId?.start_date)
                            .add(10, "days")
                            .calendar()) : '-'
                    }]} />
                </div>

                <div className="col-6">
                    <InfoDisplay title="PROJECT NUMBER" texts={[{ text: CheckValue(projectDataId?._id) }]} />
                </div>

                <div className="col-6">
                    <InfoDisplay
                        title="PROJECT DUE DATE"
                        texts={[{
                            text: projectDataId?.name ? CheckValue(moment(projectDataId?.end_date)
                                .add(10, "days")
                                .calendar()) : '-'



                        }]}
                    />
                </div>

                <div className="col-6">
                    <InfoDisplay
                        title="ORGANIZATION NAME"
                        texts={[{ text: CheckValue(organization?.name) }]}
                    />
                </div>

                <div className="col-6">
                    <InfoDisplay
                        title="PROJECT STATUS"
                        texts={[{ text: '' }]}
                    />


                    <span
                        style={{

                            fontSize: "12.5px",

                        }}
                        className={`${selectedColor(
                            !updateProjectStatus?.status
                                ? projectDataId?.status
                                : updateProjectStatus?.status
                        )} baseBadge me-1`}                    >
                        {!updateProjectStatus?.status
                            ? projectDataId?.status
                            : updateProjectStatus?.status}
                    </span>
                    {projectDataId?.status && <button
                        type="button"
                        className="btn btn-light-gray text-black btn-sm "

                        onClick={() => setView(true)}
                    >
                        CHANGE
                    </button>}
                </div>

                <div className="col-6">
                    <InfoDisplay
                        title="CLIENT NAME"
                        texts={[
                            { text: client?.first_name && client?.last_name ? CheckValue(client?.first_name + tab + client?.last_name) : '-' },
                        ]}
                    />
                </div>
            </div>

            <div className='p-0 m-0 w-100'>
                <table className="table table-borderless m-0 p-0 w-100">
                    <thead>
                        <tr className="text-primary typo-body">
                            <th scope="col" className="ps-4 HeadTableFonts">
                                JOB NAME
                            </th>
                            <th scope="col">JOB TYPE</th>
                            <th scope="col">JOB START DATE</th>
                            <th scope="col">JOB DUE DATE</th>
                            <th scope="col">STATUS</th>
                            <th scope="col">CLIENT BRIEF</th>
                        </tr>
                    </thead>

                    <tbody>
                        {jobsDataId?.map((item: any, index: any) => {
                            return (
                                <>
                                    <tr
                                        className={`${(index / 2) % 1 > 0 ? "table-primary" : ""
                                            } general-text-table-factory typo-body`}
                                        key={index}
                                    >
                                        <td className="align-middle ps-3 general-text-table-factory">
                                            <div className="d-flex">
                                                <img
                                                    className="avatar2 "
                                                    src="https://m.media-amazon.com/images/I/51P84188A4L._AC_SX466_.jpg"
                                                    alt=""
                                                    style={{ display: "none" }}
                                                />

                                                <p className="d-flex m-0 ms-2 m-2 general-text-table-factory">
                                                    {item.name}
                                                </p>
                                            </div>
                                        </td>

                                        <td className="align-middle">{item._id}</td>
                                        <td className="align-middle">
                                            {moment(item.start_date?.slice(0, -1)).format(
                                                "MM/DD/YYYY"
                                            )}
                                            {/* {moment(item.end_date).add(10, "days").calendar()} */}
                                        </td>
                                        <td className="align-middle">
                                            {moment(item.end_date?.slice(0, -1)).format(
                                                "MM/DD/YYYY"
                                            )}
                                            {/* // {moment(item.end_date).add(10, "days").calendar()} */}
                                        </td>
                                        <td className="align-middle general-text-table-factory">
                                            <span className={`complete baseBadge `}>
                                                In progress
                                            </span>
                                        </td>
                                        <td className="align-middle">
                                            <button
                                                className="m-0 typo-body general-text-table-factory text-decoration-underline"
                                                style={{
                                                    background: "none",
                                                    border: "none",
                                                }}
                                                onClick={() => {
                                                    viewBriefCLient(item)
                                                }}
                                            >
                                                VIEW
                                            </button>
                                        </td>

                                        <td className="align-middle">
                                            <div className="col-auto d-flex align-items-center">
                                                <img
                                                    src={EditIcon}
                                                    className="text-primary mx-1 cursor-pointer"
                                                    onClick={(e) => {
                                                        editProject(e, item)
                                                    }}
                                                    style={{ width: '12px', height: '12px' }}
                                                />

                                                <i className="bi bi-files text-primary mx-1" />

                                                <div className="cursor-pointer">
                                                    <img
                                                        className="mx-1"
                                                        src={TrashIcon}
                                                        alt=""
                                                        style={{
                                                            height: "12.8px",
                                                            width: "12.8px",
                                                            marginBottom: "5px",
                                                            color: " #0000",
                                                            marginTop: "3px",
                                                        }}
                                                        onClick={(e) => {
                                                            handleDeletedJob(e, item)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        {/* <div>{brief ? brief : ""}</div> */}
                                    </tr>
                                </>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <ModalStatus
                view={view}
                setView={setView}
                toggle={view}
                activeControl={activeControl}
                setActiveControl={setActiveControl}
                getNewStatus={getNewStatus}
                sendProject={sendProject}
                setSendProject={setSendProject}
            />
        </div>
    );
};

export default ProjectDetail;
