import de from "@mobiscroll/react/dist/src/i18n/de";
import axios from "axios";
import { R } from "src/models";
import {
  JobsModel,
  JobsModelId,
  ProjectModel,
  ProjectModelId,
  StatusProjectModel,
} from "src/models/factoryModel";
import { QuoteStatusData } from "src/models/QuotesModel";

const GetAllProjects = async () => {
  const response = await axios
    .get("factory/projects/all")
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data.data;
};

const GetProjectsId = async (id: ProjectModelId) => {
  const response = await axios
    .get(`factory/projects/${id}`)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data.data;
};

const CreateProject = async (project: ProjectModel) => {
  const formData = new FormData();
  formData.append("name", project.name);
  formData.append("client", project.client);
  formData.append("organization", project.organization);
  formData.append("start_date", project.start_date);
  formData.append("end_date", project.end_date);
  formData.append("file_cover", project.file_cover);

  const response = await axios
    .post<R<any>>("factory/projects", formData)
    .then((response) => {
      if (response.data) {
        return response.data;
      }
      if (response.status === 201) {
        GetAllProjects();
      }
    });
  return response?.data;
};

const DeleteProject = async (id: ProjectModelId) => {
  const response = await axios
    .delete<R<any>>(`factory/projects/${id}/`)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data.data;
};

const updateProject = async (project: any) => {
  const formData = {
    name: project.name,
    start_date: project.start_date,
    end_date: project.end_date,
    status: project.status,
  };

  const response = await axios
    .put<R<any>>(`factory/projects/${project.id}`, formData)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  if (response) {
    GetAllProjects();
  }

  return response?.data.data;
};

const GetAllJobs = async () => {
  const response = await axios.get("factory/jobs/all").catch(function (error) {
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    }
  });
  return response?.data.data;
};

const GetAllJobsTypes = async () => {
  const response = await axios
    .get("factory/jobs/types")
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data.data;
};

const GetJobsId = async (id: JobsModelId) => {
  const response = await axios
    .get(`factory/jobs/all?project=${id}`)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data.data;
};

const GetJobById = async (id: JobsModelId) => {
  const response = await axios
    .get(`factory/jobs/${id}`)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data.data;
};

const CreateJobs = async (
  job: any
  //  JobsModel
) => {
  const formData = new FormData();
  formData.append("name", job.name);
  formData.append("client_brief", job.client_brief);
  // formData.append("project", job.project);
  formData.append("start_date", job.start_date);
  formData.append("end_date", job.end_date);
  formData.append("project", job.project);

  // formData.append("asset_links", job.asset_links);
  // formData.append("asset_links", job.asset_links);
  // formData.append("types", job.types);
  // formData.append("types", job.types);
  // formData.append("milestones", job.milestones);
  // formData.append("deliverables", job.deliverables);
  // formData.append("documents", job.documents);
  // formData.append("requirements", job.requirements);
  formData.append("file_client_brief", job.file_client_brief);
  // formData.append("file_assets", job.file_assets);

  const response = await axios
    .post<R<any>>("factory/jobs", formData)
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    });

  return response?.data?.data;
};

const updateJobs = async (job: any) => {
  // const formData = {
  //   name: job.name,
  //   start_date: job.start_date,
  //   end_date: job.end_date,
  //   project: job.project,
  //   client_brief: job.client_brief,
  //   file_client_brief: job.file_client_brief,
  //   asset_links: job.asset_links,
  //   types: job.types,
  //   milestones: job.milestones,
  //   documents: job.documents,
  //   deliverables: job.deliverables,
  //   requirements: job.requirements,
  // };

  const response = await axios
    .put<R<any>>(`factory/jobs/${job.id}`, job)
    .catch(function (error) {
      console.log("error", error);
      if (error.data.message) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data.data;
};

const DeleteJobs = async (id: ProjectModelId) => {
  const response = await axios
    .delete<R<any>>(`factory/jobs/${id}/`)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data.data;
};

const GetAllQuotes = async () => {
  const response = await axios
    .get("factory/quotes/all")
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });
  return response?.data.data;
};

const GetQuotesId = async (id: any) => {
  const response = await axios
    .get(`factory/quotes/${id}`)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });
  return response?.data.data;
};

const GetRateCardVerSion = async (id: any) => {
  const response = await axios
    .get(`rate-card-versions/${id}/rate-card-items`)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });
  return response?.data.data;
};

const GetSpecificRatesbyItemId = async (idRate: string, idItem: string) => {
  const response = await axios
    .get(`rate-cards/${idRate}/items/${idItem}`)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });
  return response?.data.data;
};

const CreateQuotes = async (quote: any) => {
  let response: any = await axios.post<R<any>>("factory/quotes", quote);

  return response?.data.data;
};

const EditQuotes = async (id: any, quote: any) => {
  const response = await axios.put<R<any>>(`factory/quotes/${id}`, quote);

  console.log("response", response);

  return response?.data?.data;
};

const DeleteQuotes = async (id: ProjectModelId) => {
  const response = await axios
    .delete<R<any>>(`factory/quotes/${id}/`)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  GetAllQuotes();

  return response?.data.data;
};

const UpdateQuoteStatus = async (
  id: string,
  data: Omit<QuoteStatusData, "id">
) => {
  const response = await axios
    .patch<R<any>>(`factory/quotes/${id}/status`, data)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data.data;
};

const GetQuoteVersion = async (id: any) => {
  const response = await axios
    .get(`factory/quotes/quote-versions/${id}/quote-fields`)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });
  return response?.data.data;
};

const GetQuotePublic = async (id: any) => {
  const response = await axios
    .get(`factory/quotes/quote-versions/${id}/quote-fields`)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });
  return response?.data.data;
};

const GetTermsAndConditions = async (model: any) => {
  const response = await axios.get(`terms/${model}`).catch(function (error) {
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    }
  });
  return response?.data.data;
};

const SendQuoteMail = async (data: any , id:any) => {
  console.log('in side service' , id);
  
  const response = await axios
    .post<R<any>>(`factory/quotes/${id}/send-by-email`, data)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data.data;
};

export const factoryService = {
  GetAllProjects,
  CreateProject,
  CreateJobs,
  GetAllJobs,
  GetJobsId,
  GetAllJobsTypes,
  GetProjectsId,
  DeleteProject,
  DeleteJobs,
  GetAllQuotes,
  GetQuotesId,
  GetRateCardVerSion,
  GetSpecificRatesbyItemId,
  CreateQuotes,
  DeleteQuotes,
  updateProject,
  UpdateQuoteStatus,
  GetJobById,
  GetQuoteVersion,
  updateJobs,
  GetTermsAndConditions,
  EditQuotes,
  SendQuoteMail
};

export default factoryService;
