import { FormikProvider, Form, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import ComboBox from "../../../../UI/forms/ComboBox";
import InputFloatingLabel from "../../../../UI/forms/InputFloatingLabel";
import InputSelect from "../../../../UI/forms/InputSelect";
import ItemModel, { AssetModel, warehouse, purchase } from '../../../../../models/ItemModel'
import {
  setData, setAsset, updateValuesAssets, setDatesData, AddFormDate
  , changeAssetsErrors, setNameFiles, ChangeFormNameFile, DeleteAsset, DeleteDate, setFetchingData, UpdateInitialDate, EmptyForm
} from "../../../../../store/stockpile/EditFormSlice";
import { Button } from "reactstrap";
import { useEffect, useState, forwardRef, useImperativeHandle, useRef } from "react";
import AssetScheme from "./validation";
import { RootState } from '../../../../../store/store';
import ButtonFiles from "../../../../UI/buttons/ButtonFile";
import SimpleButton from "src/components/UI/buttons/SimpleButton";
import { Datepicker } from "@mobiscroll/react";
import moment from "moment";
import InputDate from "src/components/UI/forms/InputDate";
import SelectInput from "src/components/UI/forms/Select";

import { ConvertSize } from '../../../../../util/sizesConvert'
import { AllWareHouses } from "src/store/stockpile/stockpileSlice";
import { useRequest } from "src/hooks/useRequest";
import { useParams } from "react-router-dom";
import { AddDate, DiscardDate, setDataAsset, setDates, UpdateValueDate, UpdateValueDateChargue } from "src/store/stockpile/EditAssetForm";
import DropZone from "src/components/UI/Dropzone";


interface Props {
  setImages: (element: any) => void;
  index: number,
  dateCount?: any,
  setDateCount?: any,
  Asset?: any
}


const FormAsset = forwardRef((props: Props, ref) => {

  const input: any = useRef()
  const params = useParams<{ itemId: string; }>();

  const ASSETS = props.Asset


  const data = useSelector((state: RootState) => state.EditAssetForm.Asset);
  const ItemID: any = params.itemId
  const ArrayArraysDates = useSelector((state: RootState) => state.EditAssetForm.dates);
  const WareHouses: Array<any> = useSelector((state: RootState) => state.stockpileSlice.AllWareHouses);
  const { data: ALL_ASSETS } = useRequest<any>({ url: 'stockpile/assets/all' }) || [];

  const [ errorDigitalReceipt , setErrorDigitalReceipt ] = useState<any>()

  const [closeEffect, setCloseEffect] = useState<any>(false)
  let dateCount = ArrayArraysDates;
  const nameManual = useSelector((state: RootState) => state.EditFormSlice.files);
  const ValuesData = data
  const [fileReceipt, setFileReceipt] = useState<any>([])

  const dispatch = useDispatch()

  const setDateCount = (value: any, indexInput: any) => {


    dispatch(UpdateValueDate({ type: indexInput, date: value.date }))

    if (indexInput == 0) {
      updateCamp({ purchase: { ...ValuesData?.purchase, date: moment(value.date).format('YYYY-MM-DD') } } as AssetModel)
    }

    if (indexInput == 1) {
      updateCamp({ end_of_life: moment(value.date).format('YYYY-MM-DD') } as AssetModel)
    }

    if (indexInput == 2) {
      updateCamp({ warranty_expiration: moment(value.date).format('YYYY-MM-DD') } as AssetModel)
    }

  }

  const setDateCountChargue = (value: any, indexInput: any) => {


    dispatch(UpdateValueDateChargue({ type: indexInput, date: value.date }))

    if (indexInput == 0) {
      updateCamp({ purchase: { ...ValuesData?.purchase, date: moment(value.date).format('YYYY-MM-DD') } } as AssetModel)
    }

    if (indexInput == 1) {
      updateCamp({ end_of_life: moment(value.date).format('YYYY-MM-DD') } as AssetModel)
    }

    if (indexInput == 2) {
      updateCamp({ warranty_expiration: moment(value.date).format('YYYY-MM-DD') } as AssetModel)
    }

  }

  const base = {
    item: ItemID?._id,
    serial: ValuesData?.serial,
    purchase: {
      date: '',
      origin: '',
      price: 0
    },
    warehouse: {
      warehouse: '147723917551731713',
      location: '',
      shelf_id: ''
    },
    warranty_expiration: '',
    end_of_life: '',
    is_external: false,
    has_digital_receipt: false,
    sizes: {
      name: 'dw',
      width: '',
      height: '',
      weight: '',
      length: '',
      weight_unit: 'centimeters',
      sizes_unit: 'centimeters'
    }
  } as AssetModel

  const formik = useFormik({
    validationSchema: AssetScheme,
    initialValues: {
      ...base
    } as AssetModel,
    onSubmit: (values) => {
    }
  })

  const { errors, handleSubmit, values, setFieldValue, setValues, setErrors } = formik

  useEffect(() => {
    dispatch(AllWareHouses())
  }, [])

  const warer = WareHouses.length > 0 ? WareHouses.map((war) => {
    return { title: war.name, value: war._id }
  }) : [{ title: 'No options', value: '----' }]

  useEffect(() => {
    updateCamp({ ...ValuesData, warehouse: { ...ValuesData?.warehouse, warehouse: warer[0]?.value } } as AssetModel)
  }, [WareHouses])

  const updateCamp = (value: AssetModel) => {
    let ret: any = data
    ret = {
      ...value, item: ItemID,
      is_external: false, has_digital_receipt: false
    } as AssetModel;
    dispatch(setDataAsset(ret))
  }

  const handleDigitalReceipt = (e: any) => {
    setFileReceipt(e)
    const file = e[0]
  //  if (!file.name.match(/\.(pdf|odt|doc|docx)$/)) {
  //    setErrorDigitalReceipt('Please select valid File. (pdf , odt, doc, docx)');
   //   return false;
   // } else {
      props.setImages(file)
      dispatch(ChangeFormNameFile({ index: props.index, name: file.name }))
      updateCamp({ ...ValuesData, has_digital_receipt: true } as AssetModel)
    //}
  }

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      dispatch(changeAssetsErrors(true))
    } else {
      dispatch(changeAssetsErrors(false))
    }
  }, [errors])





  const ChargeFormAsset = () => {
    if (ASSETS) {
      updateCamp({
        ...ASSETS, 
        sizes: {
          ...ValuesData?.sizes,
          weight: ASSETS?.sizes[0]?.weight,
          length: ASSETS?.sizes[0]?.length,
          width: ASSETS?.sizes[0]?.width,
          height: ASSETS?.sizes[0]?.height,
          weight_unit: ASSETS?.sizes[0]?.weight_unit || 'kilograms',
          ...{ sizes_unit: ASSETS?.sizes[0]?.sizes_unit || 'centimeters' }
        },
        warehouse: {
          warehouse: ASSETS?.warehouse?.warehouse,
          location: ASSETS?.warehouse?.location,
          shelf_id: ASSETS?.warehouse?.shelf_id
        },
        end_of_life: '',
        warranty_expiration: ''
      } as AssetModel)


      setFieldValue('serial', ASSETS?.serial)
      setFieldValue('price' , ASSETS?.purchase?.price)
      setFieldValue('origin' , ASSETS?.purchase?.origin)
      setFieldValue('condition', ASSETS?.condition)
      setFieldValue('sizes.weight', ASSETS?.sizes[0]?.weight)
      setFieldValue('sizes.length', ASSETS?.sizes[0]?.length)
      setFieldValue('sizes.width', ASSETS?.sizes[0]?.width)
      setFieldValue('sizes.height', ASSETS?.sizes[0]?.height)
      setFieldValue('sizes.sizes_unit', ASSETS?.sizes[0]?.sizes_unit)
      setFieldValue('sizes.weight_unit', ASSETS?.sizes[0]?.weight_unit)
      setFieldValue('warehouse.location', ASSETS?.warehouse?.location)
      setFieldValue('warehouse.shelf_id', ASSETS?.warehouse?.shelf_id)
      setFieldValue('warehouse.warehouse', ASSETS?.warehouse?.warehouse)

      let dateTemp: any = []

      if (ASSETS?.purchase?.date) {
        dateTemp[0] = { type: 'date of purchase', date: moment(ASSETS?.purchase?.date).format('YYYY-MM-DD') }
        setDateCountChargue({ type: 'date of purchase', date: moment(ASSETS?.purchase?.date).format('YYYY-MM-DD') }, 0)
        // updateCamp({ purchase: { ...ASSETS?.purchase, date: moment(ASSETS?.end_of_life).format('YYYY-MM-DD') } } as AssetModel)
      }

      if (ASSETS?.end_of_life) {
        dateTemp[1] = { type: 'end of life', date: moment(ASSETS?.end_of_life).format('YYYY-MM-DD') }
        setDateCountChargue({ type: 'end of life', date: moment(ASSETS?.end_of_life).format('YYYY-MM-DD') }, 1)
        // updateCamp({ end_of_life: moment(ASSETS?.end_of_life).format('YYYY-MM-DD') } as AssetModel)
      }

      if (ASSETS?.warranty_expiration) {
        dateTemp[2] = { type: 'warranty', date: moment(ASSETS?.warranty_expiration).format('YYYY-MM-DD') }
        setDateCountChargue({ type: 'warranty', date: moment(ASSETS?.warranty_expiration).format('YYYY-MM-DD') }, 2)
        //updateCamp({ warranty_expiration: moment(ASSETS?.warranty_expiration).format('YYYY-MM-DD') } as AssetModel)
      }

      dispatch(setDates(dateTemp))

      updateCamp({ purchase: { ...ASSETS?.purchase, date: moment(ASSETS?.purchase?.date).format('YYYY-MM-DD') , price: ASSETS?.purchase?.price } } as AssetModel)


    }
  }

  useImperativeHandle(ref, () => ({

    FullDataForm() {
      ChargeFormAsset()
    }

  }));

  const checkAlreadyExistSerial = (serial: any) => {
    const com: Array<any> = ALL_ASSETS?.filter((asset: any) => asset?.serial == serial)
    return com?.length > 0 ? false : true
  }

  useEffect(() => {


    //!checkAlreadyExistSerial(formik.values.serial) && setErrors({ serial: 'the serial already exist' })


  }, [formik.values.serial, errors.serial])

  return (
    <>
      <div className="row">
        <div className="d-flex gap-3 text-primary align-items-center m-2">
        </div>
        <div className="col-sm-12 col-md-4">
          <InputFloatingLabel
            placeHolder="Serial number"
            value={ValuesData?.serial}
            onChange={(value) => {
              setCloseEffect(true)
              updateCamp({ ...ValuesData, serial: value } as AssetModel)
              setFieldValue('serial', value)
            }}
            isRequired
            errorMessage={errors.serial}
          />
        </div>

        <div className="col-sm-12 col-md-4">
          <InputFloatingLabel
            placeHolder="Price paid"
            value={ValuesData?.purchase?.price?.toString()}
            onChange={(value: any) => {
              updateCamp({ ...ValuesData, purchase: { ...ValuesData?.purchase, price: value } } as AssetModel)
              setFieldValue('purchase.price', value)
              setCloseEffect(true)
            }}
            errorMessage={errors.purchase?.price}
          />
        </div>

        <div className="col-sm-12 col-md-4">
          <InputFloatingLabel
            placeHolder="Origin of purchase"
            value={ValuesData?.purchase?.origin}
            onChange={(value) => {
              setCloseEffect(true)
              updateCamp({ ...ValuesData, purchase: { ...ValuesData?.purchase, origin: value } } as AssetModel)
            }}
          />
        </div>
      </div>

      <div className="row mt-3">
      <div className="col-sm-12 col-md-12 col-lg-6">
        <p className="text-primary typo-body">SELECT DIGITAL RECEIPT</p>
          <div className="p-0 w-100">
            <DropZone
              setFile={handleDigitalReceipt}
              files={fileReceipt}
              maxFiles={1}
              errors={errorDigitalReceipt}
              file
            />
          </div>
        </div>

        {
          dateCount.slice(0, 3).map((item: any, index: any) => {
            return (<>
              {index > 0 && <div className="col-sm-12 col-md-6 p-0 "></div>}
              <div className={`col-sm-12 col-md-6 p-0 row ${index > 0 && 'mt-2'}`}  >
                <div className="col-sm-12 col-md-4">
                  <ComboBox
                    onChange={(value) => {
                      setDateCount({ ...item.type, type: value }, index)
                    }}
                    value={
                      index == 0 ? 'date' : index == 1 ? 'endoflife' : index == 2 ? 'warranty' : ""
                    }
                    options={[
                      { title: 'Date of purchase', value: 'date' },
                      { title: 'End of life', value: 'endoflife' },
                      { title: 'Warranty Expiration', value: 'warranty' }
                    ]}
                  />
                </div>
                <div className="col-sm-12 col-md-6" >
                  <InputDate onChange={(event: any) => {
                    setDateCount({ date: event.value, ...item.date }, index)
                  }}
                    value={item.date} ></InputDate>
                </div>
                {index > 0 && <div className="col-auto">
                  <p className="text-primary poppinsText" onClick={() => {
                    dispatch(DiscardDate(index))
                  }}
                    style={{ fontSize: '40px', cursor: 'pointer', textAlign: 'center', marginTop: '-25%' }}>&times;</p>
                </div>
                }
              </div>
            </>
            )
          })
        }

      </div>

      <div className="row mt-3">
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <SelectInput
            placeholder={'Condition'}
            onChange={(value: any) => {
              updateCamp({ ...ValuesData, condition: value } as AssetModel)
              setFieldValue('condition', value)
              setCloseEffect(true)
            }}
            value={formik.values.condition}
            options={[
              { label: 'Excellent', value: 'excellent' },
              { label: 'Good', value: 'good' },
              { label: 'Fair', value: 'fair' },
              { label: 'Poor', value: 'poor' },
            ]}
          />
        </div>

        <div className="col-6 p-0">
          <div style={{ maxWidth: '200px', marginTop: '0px' }}>
            <SimpleButton onClick={() => {
              dispatch(AddDate())
            }}>
              ADD DATE
            </SimpleButton>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <p className="m-0 text-primary typo-body">Case Information</p>
          <div className=" mt-1">
            <InputSelect
              select={{
                onChange: (value: any) => {
                  updateCamp({ ...ValuesData, sizes: { ...ValuesData?.sizes, weight_unit: value } } as AssetModel)
                  //setFieldValue('sizes.weight_unit', value)
                },
                options: [
                  {
                    label: 'Kg',
                    value: 'kilograms',
                  },
                  {
                    label: 'Grams',
                    value: 'grams',
                  },
                  {
                    label: 'Pounds',
                    value: 'pounds',
                  },
                  {
                    label: 'Ounces',
                    value: 'ounces',
                  }
                ],
                value: ValuesData?.sizes?.weight_unit ? ValuesData?.sizes?.weight_unit : 'kilograms',
              }}
              input={{
                onChange: (value) => {
                  updateCamp({ ...ValuesData, sizes: { ...ValuesData?.sizes, weight: value } } as AssetModel)
                  setFieldValue('sizes.weight', value)
                },
                placeHolder: 'Weight',
                value: formik.values.sizes.weight,
                errorMessage: errors?.sizes?.weight
              }}
            />
          </div>

          <div className="mt-2">
            <InputSelect
              select={{
                onChange: (value: any) => {
                  updateCamp({ ...ValuesData, sizes: { ...ValuesData?.sizes, sizes_unit: value } } as AssetModel)
                  // setFieldValue('sizes.sizes_unit', value)
                },
                options: [
                  {
                    label: 'Cm',
                    value: 'centimeters',
                  },
                  {
                    label: 'Meter',
                    value: 'meters',
                  },
                  {
                    label: 'Inches',
                    value: 'inches',
                  },
                  {
                    label: 'Feet',
                    value: 'feet',
                  },
                ],
                value: ValuesData?.sizes?.sizes_unit ? ValuesData?.sizes?.sizes_unit : 'centimeters',
              }}
              input={{
                onChange: (value) => {
                  updateCamp({ ...ValuesData, sizes: { ...ValuesData?.sizes, length: value } } as AssetModel)
                  setFieldValue('sizes.length', value)
                },
                placeHolder: 'Length',
                value: formik.values.sizes.length,
                errorMessage: errors?.sizes?.length
              }}
            />
          </div>

          <div className="mt-2 col-md-10 float-end" style={{ paddingLeft: '7.5%', paddingRight: '.5%' }}>
            <InputFloatingLabel
              placeHolder="Width"
              value={formik.values.sizes.width}
              onChange={(value) => {
                updateCamp({ ...ValuesData, sizes: { ...ValuesData?.sizes, width: value } } as AssetModel)
                setFieldValue('sizes.width', value)
              }}
              errorMessage={errors?.sizes?.width}
            />
          </div>

          <div className="mt-2 col-md-10 float-end" style={{ paddingLeft: '7.5%', paddingRight: '.5%' }}>
            <InputFloatingLabel
              placeHolder="Height"
              value={formik.values.sizes.height}
              onChange={(value) => {
                updateCamp({ ...ValuesData, sizes: { ...ValuesData?.sizes, height: value } } as AssetModel)
                setFieldValue('sizes.height', value)
              }}
              errorMessage={errors?.sizes?.height}
            />
          </div>
        </div>

        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <p className="m-0 text-primary typo-body">Warehouse Location</p>

          <div className="mt-2">
            <ComboBox
              options={warer}
              onChange={(value) => {
                updateCamp({ ...ValuesData, warehouse: { ...ValuesData?.warehouse, warehouse: value } } as AssetModel)
                setFieldValue('warehouse.warehouse', value)
              }}
              value={formik.values.warehouse.warehouse}
            />
          </div>

          <div className="mt-2">
            <InputFloatingLabel
              placeHolder="Location within warehouse"
              value={formik.values.warehouse.location}
              onChange={(value) => {
                updateCamp({ ...ValuesData, warehouse: { ...ValuesData?.warehouse, location: value } } as AssetModel)
                setFieldValue('warehouse.location', value)
              }}
              errorMessage={errors?.warehouse?.location}
            />
          </div>

          <div className="mt-2">
            <InputFloatingLabel
              placeHolder="Shelf ID"
              value={formik.values.warehouse.shelf_id}
              onChange={(value) => {
                updateCamp({ ...ValuesData, warehouse: { ...ValuesData?.warehouse, shelf_id: value } } as AssetModel)
                setFieldValue('warehouse.shelf_id', value)
              }}
              errorMessage={errors?.warehouse?.shelf_id}
            />
          </div>
        </div>
      </div>
    </>)
})

export default FormAsset