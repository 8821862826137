import { flexbox } from '@mui/system';
import React from 'react'
import Select, {
  components,
  GroupBase,
  StylesConfig,
} from "react-select";

interface Props {
  onChange: any;
  value: any;
  options: any;
  placeholder?: any;
  className?: any;
}

const CaretDownIcon = () => {
  return (
    <>
      <svg
        enable-background="new 0 0 29 14"
        height="10px"
        id="Layer_1"
        version="1.1"
        viewBox="0 0 29 14"
        width="7px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <polygon fill="#8898AA" points="0.15,0 14.5,14.35 28.85,0 " />
      </svg>
    </>
  );
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props} className="p-1">
      <CaretDownIcon/>
    </components.DropdownIndicator>
  );
};

const IndicatorSeparator: any = (props: any) => {
  return "";
};

const colourStyles: StylesConfig<any, false, GroupBase<any>> = {
  option: (styles: any, sourc) => {
    const { isFocused, isSelected } = sourc;
    return {
      ...styles,
      background: isSelected? "#BCCADF" : "none",
      color: isSelected? "#53607F" : "6B8AB8",
      "&:hover": {
        color: "white",
        backgroundColor: isFocused ? "#6B8AB8" : null,
      },
    };
  },
  menu: (provided) => ({
    ...provided,
    borderBottom: "none",
    color: "#6B8AB8",
    width: "210px",
    zIndex: "1000",
  }),
  control: (base, state) => ({
    ...base,
    boxShadow: "none",
    color: "none",
    height: "30px",
    width: "70px",
    minHeight: 0,
    background: "none",
    justifyContent: "start",
    flexWrap: "nowrap"
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "0px 0px 0px 8px",
    fontSize: "14px",
    color: "#6B8AB8",
    fontWeight: 400,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#6B8AB8",
  }),
};

const SelectPlain: React.FC<Props> = (props) => {
  return (
    <>
      <Select
        components={{ DropdownIndicator, IndicatorSeparator }}
        placeholder={props.placeholder}
        value={props.options && props.options?.length > 0 && props.options.find((x: any) => x.value === props.value)}
        onChange={(newValue) => props.onChange(newValue.value)}
        styles={colourStyles}
        options={props.options}
        className={props.className}
        menuPortalTarget={document.body}
      />
    </>
  );
};

export default SelectPlain;
