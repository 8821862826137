import React, { useState } from "react";
import EmptyCircle from "../../../../../components/UI/EmptyCircle";
import Checkbox from "../../../../../components/UI/forms/Checkbox";
import update from "immutability-helper";

interface Props {
  handleSelect?: any;
  items: any;
  active?: boolean;
  block?: boolean | false;
  addItems?: any;
}

const AddRateItem: React.FC<Props> = (props) => {
  const [selectItems, setSelectItems] = useState<any>([]);

  // console.log("items", props.items);

  return (
    <div
      className={
        (props.active ? "active " : "") +
        (!props.block ? "border-top " : "") +
        " container-fluid p-3 py-2 contact-item"
      }
      // onClick={() => props.handleSelect(props.items)}
    >
      <div className="row align-items-center">
        <div
          className={`col d-flex p-0 text-truncate ${
            props.handleSelect ? "ms-3 bg-red" : "ms-2"
            // "ms-2"
          }`}
        >
          <div className="ms-2 d-flex ">
            <div className=" mt-2">
              <div className="col-auto p-0 me-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={selectItems}
                  // checked
                  // onChange={() => setSelectItems([...selectItems, props.items])}
                  onChange={(e) => {props.handleSelect(e.target.checked)}}
                />
              </div>
            </div>
            <div>
              {props.items.file_cover ? (
                <img
                  className="avatar2"
                  src={
                    process.env.REACT_APP_IMAGES_AWS + props.items.file_cover
                  }
                  alt="factory"
                />
              ) : (
                <EmptyCircle title={props.items.name} small={true} />
              )}
            </div>

            <div className="ms-3 d-flex flex-column">
              <p className="m-0 text-black typo-body contact-title  me-auto">
                {props.items.name}
              </p>

              <p className="m-0 text-black typo-body contact-subtitle me-auto">
                {props.items.category?.sub_category?.name}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRateItem;
