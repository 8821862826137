import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import FormularyTaskModal from "../Factory/FactoryView/formularyTaskModal/formularyTaskModal";
import JobCreateProjectModal from "../Factory/FactoryView/modalTableJob/JobCreateProjectModal";
import SelectJobModal from "../Factory/FactoryView/modalTableJob/SelectJobModal";
import { useHistory } from "react-router-dom";
import { useToast } from "src/hooks/useToast";

interface Props {
  view?: boolean;
  setView?: any;
  toggle?: any;
  activeControlView?: string;
  setActiveControlView?: any;
  selectJob: string | null;
  setSelectJob?: any;
  activeTask?: string | null;
  setActiveTask?: any;
  selectProject?: any;
  setSelectProject?: any;
  setViewOpenModalFactory?: any;
  closeModalJob: boolean;
  setCloseModalJob: any;
  viewOptions?: boolean;
  setViewOptions?: any;
}

const FactoryModal: React.FC<Props> = (props) => {
  const [activeControl, setActiveControl] = useState<string | null>(null);
  const [activeJob, setActiveJob] = useState<string | null>(null);
  const [activeButton, setActiveButton] = useState<boolean>(true);

  const history = useHistory();

  const toast = useToast()

  useEffect(() => {
    const buttonActive = () => {
      if (activeControl !== null) {
        setActiveButton(false);
      } else {
        setActiveButton(true);
      }
    };

    const buttonDisabled = () => {
      if (props.view === false) {
        setActiveButton(true);
        setActiveControl(null);
      }
    };

    buttonActive();
    buttonDisabled();
  }, [activeControl, props.view]);

  const sendSelection = () => {
    if (activeControl === "job") {
      setActiveJob(activeControl);
      // props.setViewOptions(false);
    }
    if (activeControl === "quote") {
      setActiveJob(activeControl);
      // props.setViewOptions(false);
    }
    if (activeControl === "task") {
      props.setActiveTask(activeControl);
      // props.setViewOptions(false);
    }
    if (activeControl === "project") {
      props.setActiveControlView(activeControl);
      // props.setViewOptions(false);
    }
  };

  const closeModal = () => {
    if (props.viewOptions) {
      history.replace("/factory");
      props.setViewOptions(false);
    }

    props.setView(false);
    setActiveJob(null);
    if (props.closeModalJob) {
      props.setSelectProject(null);
    }

    if (props.activeTask === undefined) {
      return null;
    } else {
      props.setActiveTask(null);
    }

    setActiveControl(null);
   //  props.setSelectProject(null);
  };

  return (
    <Modal
      isOpen={props.view}
      toggle={() => props.toggle}
      centered
      className=""
      style={
        activeJob === "job" || activeJob === "quote"
          ? { width: "850px", maxWidth: "900px" }
          : props.activeTask == "task" || props.selectProject !== null
          ? { width: "650px", maxWidth: "900px" }
          : { width: "350px" }
      }
    >
      <div className="container text-center">
        <div className="row py-2">
          <div
            onClick={closeModal}
            className="cursor-pointer"
            style={{ width: "30px" }}
          >
            <i
              className="bi bi-x-lg  "
              style={{
                fontSize: "15px",
                marginRight: "300px",
                height: "150px",
              }}
            ></i>
          </div>
          <div className="col-12 ">
            <h2 className="title-modal-factory text-secondary ">
              {activeJob
                ? "Select project"
                : props.activeTask
                ? "Create task"
                : props.selectProject !== null
                ? "Select job"
                : "Select"}
            </h2>
          </div>
          <div
            className={`col-12 ${
              activeJob === "job" || activeJob === "quote"
                ? "container-Select-factory"
                : props.activeTask
                ? "container-task-factory"
                : null
            }`}
          >
            {activeJob === "job" || activeJob === "quote" ? (
              <JobCreateProjectModal
                selectProject={props.selectProject}
                setSelectProject={props.setSelectProject}
                selectJob={props.selectJob}
                setSelectJob={props.setSelectJob}
                setActiveJob={setActiveJob}
                activeControl={activeControl}
                setActiveControl={setActiveControl}
                setActiveControlView={props.setActiveControlView}
              />
            ) : props.activeTask ? (
              <FormularyTaskModal />
            ) : props.selectProject !== null ? (
              <SelectJobModal
                setSelectJob={props.setSelectJob}
                setActiveControlView={props.setActiveControlView}
                selectProject={props.selectProject}
                setSelectProject={props.setSelectProject}
                setView={props.setView}
                setActiveTask={props.setActiveTask}
                setActiveControl={setActiveControl}
              />
            ) : props.viewOptions ? (
              <div className="container-Selector">
                {["project", "job", "quote", "task"].map((e, index) => (
                  <div
                    key={index}
                    className="container-selector-map"
                    style={e == 'task'?{ opacity: '0.2', cursor: 'pointer', textTransform: "capitalize"} :  { textTransform: "capitalize", cursor: "pointer" }}
                    onClick={() => {
                      if (e == 'task') {
                        toast.info('task option' + " is coming soon!");
                      }else{
                        setActiveControl(e)
                      }
                    }}
                  >
                    <h6
                      className={
                        activeControl === e ? "checked-factory" : "factory-link"
                      }
                    >
                      {e}
                    </h6>
                  </div>
                ))}
              </div>
            ) : (
              []
            )}
          </div>

          {activeJob === "job" ||
          activeJob === "quote" ||
          props.selectProject !== null ? null : (
            <div
              className={`col-sm-12  p-2 ${
                props.activeTask
                  ? "container-button-select-without-border"
                  : "container-button-select"
              } `}
            //  style={activeControl == "task"? { pointerEvents: 'none',opacity:'0.1'}:{}}
            >
              <div className="row justify-content-evenly">
                <button
                  className={
                    "btn btn-success  text-white col-auto btn-modal-factory"
                  }
                  disabled={activeButton}
                  onClick={sendSelection}
                >
                  {props.activeTask ? "Save task" : "Create"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default FactoryModal;
