import React, { useEffect, useState } from "react";
import moment from "moment";
import InputSearch from "../../../../../UI/forms/InputSearch";
import TrashIcon from "../../../../../../assets/icons/trash.svg";
import { useHistory, useParams } from "react-router-dom";
import FilterIcon from '../../../../../../assets/icons/filter.svg'
import QuoteEmailModal from "../../../../../Modals/Factory/Quotes/QuoteEmailModal"
import { useDispatch } from "react-redux";
import { setIdQuoteEmail } from "../../../../../../store/factory/factorySlice";

interface Props {
  QuoteData: any;
  view: boolean;
  setView: any;
  preview: boolean;
  setPreView: any;
  deleteQuoteModal: boolean;
  setDeleteQuoteModal: any;
  setIdQuoteDelete: any;
  getDataQuotes: any;
  setGetQuoteById: any;
  jobsDataId: any;
  loading: any;
  quoteAllState: any;
  setQuoteAllState: any;
}

const Quotes: React.FC<Props> = (props) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [expanded2, setExpanded2] = useState<string>("");
  const [IsOpenSendEmailModal, setIsOpenSendEmailModal] = useState(false);

  const dispatch = useDispatch()

  // const [quoteAllState, setQuoteAllState] = useState(props.QuoteData.data);
  const [searchQuoteName, setSearchQuoteName] = useState("");

  const [idEmail, setIdEmail] = useState('')

  const handleChange = (item: string) => {
    setExpanded(!expanded);
    setExpanded2(item);
  };

  const handleSelect = (id: string) => {
    // props.setActiveControlView("viewProject");
    history.push(`/factory/quotes/detail/${id}`);
  };

  const filterQuote = () => {
    let searchResult = props.QuoteData?.data?.filter((element: any) => {
      if (
        element.name
          .toString()
          .toLowerCase()
          .includes(searchQuoteName.toLowerCase())
      ) {
        return element;
      }
    });

    props.setQuoteAllState(searchResult);
  };

  useEffect(() => {
    filterQuote();
  }, [searchQuoteName]);

  useEffect(() => {
    props.setQuoteAllState(props.QuoteData.data);
  }, [props.getDataQuotes, props.QuoteData.data]);

  const history = useHistory();
  const { id }: any = useParams();

  const getInfoQuote = (quote: any) => {
    props.setGetQuoteById(quote._id);

    props.setView(true);
    props.setPreView(true);
  };

  const handleDeletedQuote = (e: any, item: any) => {
    props.setIdQuoteDelete(item._id);
    props.setDeleteQuoteModal(true);
  };

  const handleEditquote = (e: any, item: any) => {
    history.replace(`/factory/quotes/edit/${item._id}`);
  };

  const formatNumber = (number: number) => {
    let n: string = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(number);

    return n;
  };

  return (
    <div className="scrollbar-none overflow-x" style={{ height: "600px" }}>
      <div className="row p-4">
        <div className="col-4">
          <InputSearch
            placeholder="Search quote"
            value={searchQuoteName}
            onChange={(val) => setSearchQuoteName(val)}
          />
        </div>

        <button
          type="button"
          className="btn btn "
          onClick={() => { }}
          style={{
            width: 50,
            height: 43,
            border: "1.3px solid #DEE2E6",
          }}
        >
          <img src={FilterIcon} alt="" />
        </button>
      </div>
      <div className="col-lg-11 pe-5 me-1">
        <table className="table table-borderless ">
          <thead>
            <tr className="text-primary typo-body">
              <th scope="col" className="ps-4 ">
                <p className="p-0 m-0">QUOTE NAME</p>
              </th>
              <th scope="col" className="ps-0 pe-0 ">
                <p className="p-0 m-0">QUOTE NUMBER</p>
              </th>
              <th scope="col" className="ps-5 pe-0 me-0 ">
                <p className="p-0 m-0">LAST EDITED</p>
              </th>
              <th scope="col" className="">
                <p className="p-0 m-0 pe-3">STATUS</p>
              </th>
              <th scope="col" className="">
                <p className="p-0 m-0"> QUOTE TOTAL</p>
              </th>
              <th scope="col">
                <p className="p-0 m-0"> QUOTE COST</p>
              </th>
              <th scope="col">
                <p className="p-0 m-0"> QUOTE MARGIN</p>
              </th>
              <th scope="col">
                <p className="p-0 m-0">QUOTE VERSION</p>
              </th>
            </tr>
          </thead>
        </table>
      </div>

      {props.jobsDataId &&
        props.jobsDataId.map((items, index) => (
          <div className="accordion mt-0 mb-0 p-0">
            <div
              className="accordion-item p-0 border-0 m-0 rounded-0 "
              key={index}
            //style={{background: '#6b8ab859'}}
            >
              <p className="accordion-header p-0">
                <div onClick={() => handleChange(items.name)}>
                  <button
                    className="accordion-button bg-primary-acordion rounded-0 m-0 border-0"
                    style={{
                      background: "#6b8ab859",
                      color: "#53607F",
                      paddingBottom: "-5px",
                      paddingTop: "-5px",
                    }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${index}`}
                    aria-expanded="true"
                    aria-controls={`collapse${index}`}
                  >
                    <div className="PoppinsText">
                      {items.name}
                      {expanded && expanded2 === items.name ? (
                        <i className="bi bi-caret-up-fill ms-1" />
                      ) : (
                        <i className="bi bi-caret-down-fill ms-1" />
                      )}
                    </div>
                  </button>
                </div>
              </p>

              <div
                id={`collapse${index}`}
                className="accordion-collapse collapse"
                aria-labelledby="headingOne"
              >
                <div className="accordion-body text-primary m-0 p-0">
                  <div className="w-100 custom-scrollbar overflow-x">
                    <table className="table table-borderless">
                      <tbody>
                        {props.loading ? (
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ width: "88vw", height: "30vh" }}
                          >
                            <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block" />
                            <span
                              className="text-black
                                ms-3"
                            >
                              Loading...
                            </span>
                          </div>
                        ) : (
                          props.quoteAllState &&
                          props.quoteAllState
                            .filter((fil: any) => fil.job === items._id)
                            .map((item: any, index: number) => {
                              return (
                                <tr
                                  className={`${(index / 2) % 1 > 0 ? "table-primary" : ""
                                    } text-primary typo-body general-text-table-factory`}
                                  key={index}
                                  style={{ height: "50px" }}
                                >
                                  <td
                                    className="align-middle "
                                    style={{
                                      width: "11.2%",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleSelect(item._id)}
                                  >
                                    <p className="p-0 m-0 ms-4 ">{item.name}</p>
                                  </td>
                                  <td
                                    className="align-middle"
                                    style={{ width: "13.5%" }}
                                  >
                                    <p className="pt-3">{item._id}</p>
                                  </td>
                                  <td
                                    className="align-middle "
                                    style={{ width: "10.5%" }}
                                  >
                                    {moment(item.due_date).format("DD/MM/YYYY")}
                                  </td>
                                  <td
                                    className="align-middle "
                                    style={{ width: "9%" }}
                                  >
                                    <span className={`onHold baseBadge`}>
                                      {item.status}
                                    </span>
                                  </td>
                                  <td
                                    className="align-middle "
                                    style={{ width: "10.5%" }}
                                  >
                                    {formatNumber(item.total)}
                                  </td>
                                  <td
                                    className="align-middle "
                                    style={{ width: "10.5%" }}
                                  >
                                    $0.00
                                  </td>
                                  <td
                                    className="align-middle"
                                    style={{ width: "14.5%" }}
                                  >
                                    $0.00
                                  </td>
                                  <td
                                    className="align-middle"
                                    style={{ width: "210px" }}
                                  >
                                    {item.quote_version[0].version}
                                  </td>

                                  <td className="align-middle">
                                    <div className="col-auto d-flex align-items-center">
                                      <div className="cursor-pointer">
                                        <i className="bi bi-send-fill text-primary mt-1 me-1" onClick={() => {
                                        dispatch(setIdQuoteEmail(item._id))
                                        console.log('ITEM QUOTE FOR MODAL' , idEmail);
                                        
                                        setIsOpenSendEmailModal(true)
                                      }} ></i>
                                      </div>
                                      <i
                                        className="bi bi-eye-fill text-primary mx-1 cursor-pointer"
                                        onClick={() => getInfoQuote(item)}
                                      ></i>
                                      <i
                                        className="bi bi-pencil-fill text-primary mx-1 cursor-pointer"
                                        onClick={(e) =>
                                          handleEditquote(e, item)
                                        }
                                      />
                                      <div
                                        className="cursor-pointer"
                                        onClick={(e) =>
                                          handleDeletedQuote(e, item)
                                        }
                                      >
                                        <img
                                          className="mx-1"
                                          src={TrashIcon}
                                          alt=""
                                          style={{
                                            height: "12.8px",
                                            width: "12.8px",
                                            marginBottom: "5px",
                                            color: " #0000",
                                            marginTop: "3px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      <QuoteEmailModal
        isOpen={IsOpenSendEmailModal}
        onClose={() => setIsOpenSendEmailModal((prev) => !prev)}
        width={"460px"}
        IdQuote={idEmail}
      />
    </div>
  );
};

export default Quotes;
