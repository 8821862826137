import React from "react";
import { formatNumber } from "../../../../../util";
import Select from "../../../../UI/forms/Select";

interface Props {
    data: {
        sub_total: number,
        discount: number,
        agency_fee: number,
        tax: number,
    }
    onChange: (name, value) => void;
}

const QuoteSummary: React.FC<Props> = (props) => {
    const {data, onChange} = props;
    const options = [
        {
          label: "0%",
          value: 0,
        },
        {
          label: "IVA(12%)",
          value: 12,
        },
        {
          label: "ISR(25%)",
          value: 25,
        },
        {
          label: "L.A(9%)",
          value: 9,
        },
    ];
    
    return(
        <div className="w-100 d-flex justify-content-end">
            <table className="table table-striped table-borderless" style={{width: '32%'}}>
                <thead>
                    <tr className="text-primary typo-body align-middle">
                        <th className="py-3" style={{width: '40%'}}>QUOTE SUMMARY</th>
                        <th className="py-3" style={{width: '20%'}}>COST</th>
                        <th className="py-3" style={{width: '20%'}}>MARGIN</th>
                        <th className="py-3" style={{width: '20%'}}>TOTAL</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="text-primary inter typo-body align-middle">
                        <td>SUBTOTAL</td>
                        <td>{formatNumber(data.sub_total)}</td>
                        <td>{formatNumber(data.sub_total)}</td>
                        <td>{formatNumber(data.sub_total)}</td>
                    </tr>
                    <tr className="text-primary inter typo-body align-middle">
                        <td colSpan={2}>DISCOUNT</td>
                        <td>{formatNumber((data.sub_total*(data.discount/100)))}</td>
                        <td>{`${data.discount}%`}</td>
                    </tr>
                    <tr className="text-primary inter typo-body align-middle">
                        <td colSpan={2}>AGENCY FEE</td>
                        <td>{formatNumber((data.agency_fee/100)*(data.sub_total*(1 - data.discount/100)))}</td>
                        <td>
                            <input type="number" className="w-100 form-control text-primary h-25 p-1" style={{fontSize: '12px'}} name='agency_fee' value={data.agency_fee} min={0} onChange={(e) => onChange(e.target.name, parseFloat(e.target.value))}/>
                        </td>
                    </tr>
                    <tr className="text-primary inter typo-body align-middle">
                        <td colSpan={2}>TAX</td>
                        <td>{formatNumber((data.tax/100)*(data.sub_total*(1 - data.discount/100)))}</td>
                        <td>
                            <Select
                                placeholder={"0$"}
                                onChange={(value) => onChange('tax', parseFloat(value))}
                                value={data.tax}
                                options={options}
                                styles= {{
                                    valueContainer :{
                                        padding: "2px",
                                    },
                                    singleOption: () => ({
                                        fontSize: "12px"
                                    }),
                                    placeholder: {
                                        fontSize: "12px"
                                    },
                                }}
                                height={28}
                            />
                        </td>
                    </tr>
                    <tr className="text-primary inter typo-body align-middle">
                        <td colSpan={3}>TOTAL</td>
                        <td>{formatNumber(((data.agency_fee+data.tax+100)/100)*(data.sub_total*(1 - data.discount/100)))}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default QuoteSummary;