import React from "react";
import { QuoteRowType } from "../../../../../models/QuotesModel";
import HeaderQuote from "./Header";

interface Props {
    headers: any[];
    handleChangeRow: (path: string, value) => void;
    handleDeleteRow: (path: string) => void;
    handleAddSubHeader: (path: string) => void;
    handleAddItems: (path: string) => void;
    handleDragRow: (e, path, type) => void;
    handleDropRow: (e, path, type) => void;
}

const QuoteTable: React.FC<Props> = (props) => {
  const {headers, handleChangeRow, handleDeleteRow, handleAddSubHeader, handleAddItems, handleDragRow, handleDropRow} = props;

  return (
    <div className="w-100 custom-scrollbar overflow-x mt-3 custom-table">
        <table className="table table-borderless">
        <thead>
            <tr className="text-primary typo-body align-middle">
                <th style={{width: "4%"}}><i className="bi bi-arrows-angle-expand me-3 fs-5 cursor-pointer"></i></th>
                <th style={{width: "32%"}}><span className="required">ITEM</span></th>
                <th style={{width: "15%"}}>QTY</th>
                <th style={{width: "15%"}}>RATE</th>
                <th style={{width: "15%"}}>UNITS</th>
                <th style={{width: "15%"}}>TOTAL</th>
                <th style={{width: "4%"}}></th>
            </tr>
        </thead>
        <tbody>
            {
                headers.map((e, index) => //aumentar logica para las funciones repetidas on change
                    <HeaderQuote 
                        name={e.name} 
                        key={index} 
                        sub_headers={e.sub_headers} 
                        elements={e.elements} 
                        onChange={(path, value) => handleChangeRow(`${index}.${path}`, value)} 
                        onDelete={ () => handleDeleteRow(`${index}`)} 
                        onDeleteItem={ (itemIndex) => handleDeleteRow(`${index}.elements.${itemIndex}`)}
                        onDeleteSubHeader={ (subHeaderIndex) => handleDeleteRow(`${index}.sub_headers.${subHeaderIndex}`)}
                        onDeleteSubHeaderRow={ (subHeaderIndex, subHeaderRowIndex) => handleDeleteRow(`${index}.sub_headers.${subHeaderIndex}.elements.${subHeaderRowIndex}`)}
                        onAddSubHeader={() => handleAddSubHeader(`${index}.sub_headers`)}
                        onAddItems = {() => handleAddItems(`${index}.elements`)}
                        onAddSubHeaderItems = {(subHeaderIndex) => handleAddItems(`${index}.sub_headers.${subHeaderIndex}.elements`)}
                        onDrag = {(e) => handleDragRow(e, `${index}`, QuoteRowType.HEADER)}
                        onDrop = {(e) => handleDropRow(e,`${index}`, QuoteRowType.HEADER)}
                        onDragItem = {(e, path) => handleDragRow(e, `${index}.${path}`, QuoteRowType.ITEM)}
                        onDropItem = {(e, path) => handleDropRow(e,`${index}.${path}`, QuoteRowType.ITEM)}
                        onDropSubHeader = {(e, subHeaderIndex) => handleDropRow(e,`${index}.sub_headers.${subHeaderIndex}`, QuoteRowType.SUB_HEADER)}
                        onDragSubHeader = {(e, subHeaderIndex) => handleDragRow(e,`${index}.sub_headers.${subHeaderIndex}`, QuoteRowType.SUB_HEADER)}
                    />
                )
            }
        </tbody>
        </table>
    </div>
  );
};

export default QuoteTable;
