export enum QuoteStatusType {
    IN_REVIEW = 'In Review',
    FEEDBACK = 'FeedBack',
    APPROVED = 'Approved',
    REJECTED = 'Rejected',
}

export enum QuoteRowType{
    HEADER = 'HEADER',
    SUB_HEADER = 'SUB_HEADER',
    ITEM = 'ITEM'
}

export enum QuoteUnitsType{
    DAY = 'daily',
    HOLIDAY_DAY = 'holiday_day',
    HOLIDAY_HOUR = 'holiday_hour',
    HOLIDAY_OVERTIME_HOUR = 'holiday_overtime_hour',
    HOUR = 'hour',
    OVERTIME_HOUR= 'overtime_hour',
    SALE = 'sale',
    WEEK = 'week'
}

export interface QuoteStatusData {
    id : string,
    status : QuoteStatusType
}

export interface IQuoteJob {
    id: string,
    name: string,
    client_brief: string
}

export interface ICreateQuote {
    name: string,
    description: string,
    job: IQuoteJob,
    due_date: string,
    term: string,
    target_budget: number,
    expiration_date: string,
    quote_budget: number,
    discount: number,
    sub_total: number,
    agency_fee: number, 
    tax: number, 
    headers: IHeaderQuote[]
}

export interface IHeaderQuote {
    name: string,
    elements: IElementQuote[],
    sub_headers: ISubHeaderQuote[],
}

export interface ISubHeaderQuote {
    name: string,
    elements: IElementQuote[]
}

export interface IElementQuote {
    item: string,
    name: string,
    category: string,
    quantity: number,
    rate: number,
    units_number: number,
    units_type: string
}

