import React from "react";
import { QuoteUnitsType } from "../../../../../../models/QuotesModel";
import { formatNumber } from "../../../../../../util";
import SelectPlain from "../../../../../UI/forms/SelectPlain";

interface Props {
    isVisible?: boolean;
    isSubRow?: boolean;
    item: any;
    onChange: (name: string, value) => void;
    onDelete: () => void;
    onDrag: (e) => void;
    onDrop: (e) => void;
}

const options = Object.values(QuoteUnitsType).map( (e) => ({label: e, value: e}));

const ItemRow: React.FC<Props> = (props) => {
    const {isVisible, isSubRow, item, onChange, onDelete, onDrag, onDrop} = props; 

    return (
        <tr className={`text-secondary align-middle ${isVisible? '' : 'd-none'}`} draggable onDragStart={(e) => onDrag(e)} onDrop={(e) => onDrop(e)} onDragOver={(e) => e.preventDefault()}>
            <td colSpan={2}>
                <div className="d-flex align-items-center">
                    <i className="bi bi-list fs-3 me-3 cursor-pointer" style={{marginLeft: isSubRow? '2rem' : '1rem'}}></i>
                    <div className="d-flex flex-column me-3">
                        <p className="fs-6 mb-0">{item.name}</p>
                        <p className="fs-7 mb-0">{item.category}</p>
                    </div>
                </div>
            </td>
            <td>
                <input type="number" className="form-control h-25 w-50" name='quantity' value={item.quantity} min={0} onChange={(e) => onChange(e.target.name, parseInt(e.target.value))}/>
            </td>
            <td>
                <input type="number" className="form-control h-25 w-50" name='rate' value={item.rate} min={0} onChange={(e) => onChange(e.target.name, parseFloat(e.target.value))}/>
            </td>
            <td>
                <div className="d-flex">
                    <input type="number" className="form-control h-25 w-25" name='units_number' value={item.units_number} min={0} onChange={(e) => onChange(e.target.name, parseInt(e.target.value))}/>
                    <SelectPlain
                        className="d-flex align-items-center ms-1"
                        placeholder={item.units_type}
                        value={item.units_type}
                        options={options}
                        onChange={(val: any) => onChange("units_type",val)}
                    />
                </div>
            </td>
            <td>
                <span className="fs-6">{formatNumber(item.rate*item.units_number*item.quantity || 0)}</span>
            </td>
            <td>
                <i className="bi bi-dash-circle text-danger cursor-pointer fs-5" onClick={onDelete}></i>
            </td>
        </tr>
    )
};

export default ItemRow;