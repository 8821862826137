import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import CreateProjectForm from "../components/Factory/FactoryView/createProyectForm/CreateProjectForm";
import CreateQuoteForm from "../components/Factory/FactoryView/createQuoteForm/CreateQuoteForm";
import FactoryView from "../components/Factory/FactoryView/NewLayout/FactoryView";
import FatoryViewDetailProject from "../components/Factory/FactoryView/FactoryViewDetailProject";
import useQuery from "../hooks/useQuery";
import { layoutConfig } from 'src/store/layout';
import CounterBar from "../components/Factory/FactoryView/CounterBar";
import ButtonIconText from "../components/UI/buttons/ButtonIconText";
import DropdownCustom from "../components/UI/Dropdown/dropdown";

import BarcodeIcon from "../assets/icons//barcode.svg"
import CopyIcon from "../assets/icons/copy.svg";

import IconButton from "../components/UI/buttons/IconButton";
import ProjectList from "../components/Factory/FactoryView/NewLayout/ProjectList/ProjectList";
import JobList from "../components/Factory/FactoryView/NewLayout/JobList/JobList";
import ProjectView from "../components/Factory/FactoryView/NewLayout/ProjectViewDetails/projectView";
import StockViewEmpty from "src/components/Molecules/StockViewEmptySelect";

const FactoryNew: React.FC = () => {
  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<{ id: any; }>();

  const [activeControlView, setActiveControlView] =
    useState<string | null>(null);
  const [selectJob, setSelectJob] = useState<any>(null);
  const [activeTask, setActiveTask] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectProject, setSelectProject] = useState<any>(null);
  const [closeModalJob, setCloseModalJob] = useState(false);
  const contactViewCard = useRef<HTMLDivElement>(null);
  const [isAddingContact, setIsAddingContact] = useState<boolean>(false);
  const [isContactViewOpen, setIsContactViewOpen] = useState<boolean>(false);
  const { idproject, idjob }: any = useParams();
  const [allscreen, setAllScreen] = useState<boolean>(false);

  const [filterModal, setFilterModal] = useState<boolean>(false);



  useEffect(() => {
    dispatch(layoutConfig.setModuleTitle('FACTORY'));
  }, []);


  const toggleAddingContact = (category?: string) => {
    if (isAddingContact) {
      query.delete('create');
      query.delete('category');
      history.replace({ search: query.toString() })
      dispatch(layoutConfig.setModuleTitle('BLACKBOOK'));
    } else {
      query.set('create', category || '');
      history.push({ search: query.toString() })
    }

    setIsAddingContact((prev) => !prev);
  };

  return (
    <div className={"h-100"}>

      <>
        {isAddingContact && query?.get('create') !== 'csv' && (
          <></>
        )}

        {isAddingContact && query.get('create') && query.get('create') === 'csv' && (
          <></>
        )}

        <div
          className={`container-fluid h-100 ${isAddingContact ? 'd-none' : 'd-block'
            }`}
          style={{ position: 'fixed', width: '94%' }}
        >
          <div className="row" style={{ height: '98%' }}>


            {activeControlView === null && !allscreen ? (
              <div className="col-2 card h-100">
                <FactoryView
                  activeControlView={activeControlView}
                  setActiveControlView={setActiveControlView}
                  selectJob={selectJob}
                  setSelectJob={setSelectJob}
                  activeTask={activeTask}
                  setActiveTask={setActiveTask}
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                  selectProject={selectProject}
                  setSelectProject={setSelectProject}
                  closeModalJob={closeModalJob}
                  setCloseModalJob={setCloseModalJob}
                  filterModal={filterModal}
                  setFilterModal={setFilterModal}
                />
              </div>
            ) : null
            }

            {
              activeControlView === "project" || activeControlView === "job" ?
                (
                  <div className="col h-100">
                    <CreateProjectForm
                      setActiveControlView={setActiveControlView}
                      setOpenModal={setOpenModal}
                      activeControlView={activeControlView}
                      selectJob={selectJob}
                      setSelectJob={setSelectJob}
                      setSelectProject={setSelectProject}
                    />
                  </div>
                ) : null
            }
            {activeControlView === null ? (<>
              <div className={`col card p-0 h-100 d-flex ${isContactViewOpen ? 'd-none' : 'd-block'}`} style={{ overflow: 'hidden' }} >
                <div className="  p-0" style={{ height: '60%' }}>
                  <ProjectList
            activeControlView={activeControlView}
            setActiveControlView={setActiveControlView}
            selectJob={selectJob}
            setSelectJob={setSelectJob}
            activeTask={activeTask}
            setActiveTask={setActiveTask}
            openModal={openModal}
            setOpenModal={setOpenModal}
            selectProject={selectProject}
            setSelectProject={setSelectProject}
            closeModalJob={closeModalJob}
            setCloseModalJob={setCloseModalJob}
                    filterModal={filterModal}
                    setFilterModal={setFilterModal}
                  />
                </div>
                <JobList />
              </div>

              <div
                ref={contactViewCard}
                style={{ flexGrow: isContactViewOpen ? 1 : 2 }}
                className="col card h-100 overflow-y overflow-x-none custom-scrollbar trans-all">
               {params.id ? <ProjectView setAllscreen={setAllScreen} allscreen={allscreen} /> : <StockViewEmpty element="project" /> }
              </div>
            </>) : null}


            {activeControlView === "editProject" ? (
              //  && selectJob !== null
              <CreateProjectForm
                setActiveControlView={setActiveControlView}
                setOpenModal={setOpenModal}
                activeControlView={activeControlView}
                selectJob={selectJob}
                setSelectJob={setSelectJob}
                setSelectProject={setSelectProject}
              />
            ) : idproject && idjob ? (
              <>
              </>
            ) : activeControlView === "viewProject" && selectJob !== null ? (
              <FatoryViewDetailProject
                setActiveControlView={setActiveControlView}
                setActiveTask={setActiveTask}
                openModal={openModal}
                setOpenModal={setOpenModal}
                setSelectJob={setSelectJob}
                selectJob={selectJob}
                activeTask={activeTask}
                activeControlView={activeControlView}
                selectProject={selectProject}
                setSelectProject={setSelectProject}
                closeModalJob={closeModalJob}
                setCloseModalJob={setCloseModalJob}
              />
            ) : null}

          </div>
        </div>
      </>
    </div>
  );
};

export default FactoryNew;
