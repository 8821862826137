import FlatList from 'flatlist-react';
import { Modal } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { getSections } from '../../util/ItemsFunctions';
import RateSection from '../Rates/RateList/RateSection';
import RateListEmpty from '../Rates/RateList/RateListEmpty';
import RateItem from '../Rates/RateList/RateItem';
import { useEffect, useState, Fragment } from 'react';
import {
  getAllItems,
} from "../../store/stockpile/stockpileSlice";
import ItemModel from '../../models/ItemModel';
import { RootState } from '../../store/store';
import AddItemsSection from '../Factory/FactoryView/createQuoteForm/modal/AddItemsSection';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSelectItems: (items: ItemModel[]) => void;
}

const SelectItem: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { ItemData, loading } = useSelector((state: RootState) => state.stockpileSlice);
  const [selectedItems, setSelectedtItems] = useState<ItemModel[]>([]);

  useEffect(() => {
    dispatch(getAllItems());
  }, [dispatch])

  let sections = getSections(ItemData, 'name');

  const handleChange = (item: ItemModel, checked: boolean) => {
    if(checked){
      setSelectedtItems([...selectedItems,item]);
    }else{
      setSelectedtItems(selectedItems.filter(e => e._id !== item._id));
    }
  }

  const handleSelectItems = () => {
    props.onSelectItems(selectedItems);
    setSelectedtItems([])
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.onClose} centered>
      <div className="container">
        <div className="cursor-pointer m-1" onClick={props.onClose}>
          <i className="bi bi-x-lg fs-5"></i>
        </div>
        <div className="row py-4">
          <div className="col-12">
            <h2 className="text-secondary typo-display fw-bold text-center">
              Select Items
            </h2>
          </div>
          <div className="col-12 input-icon mt-2 d-flex align-items-center">
            <i className="bi bi-search text-primary mx-2"></i>
            <input
              className="form-control typo-body text-primary"
              type="search"
              id="example-search-input"
              placeholder="Search"
            />
          </div>
        </div>
        <div className="row position-relative mx-0">
          <div className="col p-0 h-100 contact-list custom-scrollbar" style={{maxHeight: '400px'}}>
            {!loading && (
              <Fragment>
                {ItemData.length > 0 ? (
                  <FlatList
                    list={Object.keys(sections)}
                    renderItem={(item: string, index: number) => {
                      return (
                        <AddItemsSection
                          ref={sections[item].ref}
                          key={index}
                          letter={item}
                          items={sections[item].items}
                          handleSelect={handleChange}
                        />
                      );
                    }}
                  />
                ) : (
                  []
                )}
              </Fragment>
            )}
          </div>

          {/* <div className="list-linked ps-0 pe-1 py-0 overflow-y hide-scrollbar h-100">
            {Object.keys(sections).map((item, index) => {
              return (
                <p
                  // onClick={goTo.bind(null, item)}
                  key={index}
                  className="m-0 p-0 typo-body text-primary"
                >
                  {item}
                </p>
              );
            })}
          </div> */}
        </div>
        <div className="row d-flex align-items-center m-4">
          <button
            className="btn btn-success text-white m-auto w-50"
            onClick={() => handleSelectItems()}
          >
            Add Items
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SelectItem;
