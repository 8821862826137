import React, { useState } from "react";
import ItemRow from "./Rows/ItemRow";
import MainRow from "./Rows/MainRow";
import SubHeaderQuote from "./SubHeader";

interface Props {
    elements: any[];
    sub_headers: any[];
    name: string;
    onChange: (path: string, value) => void;
    onDelete: () => void;
    onDeleteSubHeader: (i: number) => void;
    onDeleteItem: (i: number) => void;
    onDeleteSubHeaderRow: (i: number, j: number) => void;
    onAddSubHeader: () => void;
    onAddItems: () => void;
    onAddSubHeaderItems: (i) => void;
    onDrag: (e) => void;
    onDragItem: (e, path: string) => void;
    onDragSubHeader: (e, i: number) => void;
    onDropItem: (e, path: string) => void;
    onDrop: (e) => void;
    onDropSubHeader: (e,i: number) => void;
}

const HeaderQuote: React.FC<Props> = (props) => {
    const [isExpand, setIsExpand] = useState(true);
    const { sub_headers, elements, name, onChange, onDelete, onDeleteSubHeader, onDeleteItem, onAddSubHeader, onAddItems, onDeleteSubHeaderRow, onAddSubHeaderItems, onDragItem, onDropItem, onDrop, onDropSubHeader, onDrag, onDragSubHeader } = props;

    const handleAddSubHeader = () => {
        onAddSubHeader();
        setIsExpand(true);
    }
    //agregar verificacion de vacio para expandir
    const handleAddItems = () => {
        onAddItems();
        setIsExpand(true);
    }

    return (
        <>
            <MainRow name={name} isExpand={isExpand} isVisible onExpand={() => setIsExpand((prev) => !prev)} onChange={onChange} onDelete={onDelete} onAddSubHeader={handleAddSubHeader} onAddItems={handleAddItems} onDrop={onDrop} onDrag={onDrag}/>
            {elements?.map((e, index) =>
                <ItemRow
                    isVisible={isExpand}
                    item={e} 
                    onChange={(name, value) => onChange(`elements.${index}.${name}`, value)} 
                    onDelete={() => onDeleteItem(index)}
                    onDrag = {(e) => onDragItem(e,`elements.${index}`)}
                    onDrop = {(e) => onDropItem(e,`elements.${index}`)} 
                />
            )}
            {sub_headers?.map((e, index) =>
                <SubHeaderQuote
                    name={e.name}
                    isVisible={isExpand}
                    elements={e.elements}
                    onChange={(path, value) => onChange(`sub_headers.${index}.${path}`, value)}
                    onDelete={() => onDeleteSubHeader(index)}
                    onDeleteItem={(rowIndex) => onDeleteSubHeaderRow(index, rowIndex)}
                    onAddItems={() => onAddSubHeaderItems(index)}
                    onDragItem = {(e, path) => onDragItem(e,`sub_headers.${index}.${path}`)}
                    onDropItem = {(e, path) => onDropItem(e,`sub_headers.${index}.${path}`)}
                    onDrop = {(e) => onDropSubHeader(e,index)}
                    onDrag = {(e) => onDragSubHeader(e,index)}
                />
            )}
        </>
    );
};

export default HeaderQuote;