import FlatList from "flatlist-react";
import React, { useEffect, useState, Ref } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button, Row, Input } from "reactstrap";
import AdvanceSearch from "./AdvanceSearch";
import ContactSection from "./ContactSection";
import IconButton from "../../UI/buttons/IconButton";
import { RootState } from "../../../store/store";
import {
  getAllItems,
  cleanAssetData,
} from "../../../store/stockpile/stockpileSlice";
//import { getSections } from "../../../util/ItemsFunctions";
import { ABC, getSections } from "../../../util/index";
import Scanner from "../../Scanner/scanner";
import DropdownCustom from "../../UI/Dropdown/dropdown";
import ButtonIconText from "../../UI/buttons/ButtonIconText";
import ItemModel from "src/models/ItemModel";
import PlusIcon from "../../../assets/icons/add.svg";
import CopyIcon from "../../../assets/icons/copy.svg";
import BarcodeIcon from "../../../assets/icons/barcode.svg";
import SearchIcon from "../../../assets/icons/search.svg";
import Kit from "../../../assets/icons/kit.svg";
import LetterSection from "src/components/DashboardLayout/MidCard/LetterSection";
import ListItem from "src/components/DashboardLayout/MidCard/ListItem";
import { useHistory } from "react-router-dom";
import ContactItem from "./ContactItem";
import {
  cleanAsset,
  setDataAsset,
  setIdItem,
} from "src/store/stockpile/selectAssetsSlice";
import useQuery from "src/hooks/useQuery";
import { setShowAddUploadCSV } from "src/store/stockpile/RecursosVisualeSlice";
import { setData } from "src/store/stockpile/createFormSlice";
import { useToast } from "src/hooks/useToast";
import {
  setCategorieActive,
  setCategoryFilter,
} from "src/store/stockpile/categorieSlice";
import ItemListComponent from "src/components/Molecules/ItemListComponent";

interface props {
  data: string;
  onAddContact: any;
  onAddService: any;
  toggleAddingkit: any;
}

const ItemList: React.FC<any> = (props) => {
  const toast = useToast();
  const history = useHistory();
  const [itemSearch, setItemSearch] = useState<string>("");
  const [itemsFiltered, setItemsFiltered] = useState<any>([]);
  const [shotby, setShortBy] = useState<string>("name");
  const [code, setCoder] = useState<any>("");
  const [scanModal, setScanModal] = useState<boolean>(false);
  const [test, settest] = useState<boolean>(false);
  const query = useQuery();
  const [advcCategory, setAdvanceCategory] = useState<any>(false);
  const [words, setWords] = useState<any>("");
  const [type, setType] = useState<any>("");
  const { innerHeight } = window;

  const [initialEmpty, setInitialEmpty] = useState<any>(false);
  const [alphSelected, setAlphSelected] = useState<any>();

  const ItemData = useSelector(
    (state: RootState) => state.stockpileSlice.ItemData
  );
  const LoadingItems = useSelector(
    (state: RootState) => state.stockpileSlice.LoadingItems
  );
  const categorySelected: any = useSelector(
    (state: RootState) => state.categorieSlice.categorySelectedFilter
  );
  const SubCategory: any = useSelector(
    (state: RootState) => state.categorieSlice.sub_categorySelectedFilter
  );

  const subCategorySelected: any = useSelector(
    (state: RootState) => state.categorieSlice.categorySelectedFilter
  );
  const ShowAddUploadCSV = useSelector(
    (state: RootState) => state.RecursosVisualeSlice.ShowAddUploadCSV
  );

  const ASSET_SELECTED = useSelector(
    (state: RootState) => state.selectAssetsSlice.data
  );

  const [indexList, setIndex] = useState("");

  const checkSelect = (indexone: any, indextwo: any) => {
    if (indexone == indextwo) {
      return true;
    }
    return false;
  };

  const dispatch = useDispatch();

  const abc: any = [
    "A",
    "B",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "Ñ",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  const getData = () => {
    dispatch(getAllItems());
  };

  useEffect(() => {
    getData();
    cleanAssetData();
    // dispatch(setIdItem(''))
  }, []);

  useEffect(() => {}, [ItemData]);

  useEffect(() => {
    dispatch(setDataAsset({}));
  }, []);

  useEffect(() => {
    console.log("changed");
  }, [ItemData]);

  const handleSearch = (e: any) => {
    setItemSearch(e.target.value);
  };

  const byCategoryForID = (item: any, category: any) => {
    if (category) {
      if (category == "any") {
        return true;
      } else {
        const result = item.category?._id == category ? true : false;

        return result;
      }
    } else return true;
  };

  const bySubCategoryForID = (item: any, sub_category: any) => {
    if (sub_category) {
      if (sub_category == "any") {
        return true;
      } else {
        const result =
          item?.category?.sub_category?._id == sub_category ? true : false;

        return result;
      }
    } else return true;
  };

  const byType = (item: any, type: any) => {
    if (type) {
      if (type == 0) {
        return item.internal_assets > 0;
      }
      if (type == 1) {
        return item.external_assets > 0;
      }
    } else return item;
  };

  const byWordsName = (item: any, word: any) => {
    if (word) {
      return item.name.toLowerCase().includes(word.toLowerCase());
    } else return item;
  };

  const byWordsDesc = (item: any, word: any) => {
    if (word) {
      return item.description.toLowerCase().includes(word.toLowerCase());
    }
  };

  const bySearch = (item: any, search: any) => {
    if (search) {
      return item.name.toLowerCase().includes(search.toLowerCase());
    } else return item;
  };
  const FWords = (item: any, words: any) => {
    if (words) {
      return item.name.toLowerCase().includes(words.toLowerCase());
    } else return item;

    /* if (words.length > 0) {
             const mp = words?.reduce((acc: Array<object>, word: any) => {
                 return acc.concat(items
                     .filter((item: any) => byWordsName(item, word))
                     .filter((item: any) => byWordsDesc(item, word))
                 )
             }, [])
             return mp
         } else return items*/
  };

  const filteredList = (
    items: any,
    search: any,
    cod: any,
    category: any,
    subCategory: any,
    AdvanceCategory: any,
    type: any,
    words: any
  ) => {
    const t = items
      .filter((item: any) => byCategoryForID(item, category))
      .filter((asset: any) => bySearch(asset, cod))
      .filter((item: any) => bySearch(item, search))
      .filter((item: any) => byCategoryForID(item, AdvanceCategory))
      .filter((item: any) => byType(item, type))
      .filter((item: any) => bySubCategoryForID(item, subCategory))
      .filter((item: any) => FWords(item, words));

    return t;
  };

  const messageComming = (message: any) => {
    toast.info(message + " is coming soon!");
  };
  //  const sections = getSections(FWords(filteredList(ItemData, itemSearch, code, categorySelected, advcCategory, type, words), words), 'name')
  const sections = getSections<ItemModel, HTMLDivElement>(
    [
      ...filteredList(
        ItemData,
        itemSearch,
        code,
        categorySelected,
        SubCategory,
        advcCategory,
        type,
        words
      ),
    ] || [],
    (item) => shotby
  );

  const results = filteredList(
    ItemData,
    itemSearch,
    code,
    categorySelected,
    SubCategory,
    advcCategory,
    type,
    words
  );

  const SelectCategory = (category: any) => {
    props.setTypeForm(category);
    props.onAddContact(category);
  };

  const toggleAddingCSV: any = (category: any) => {
    if (ShowAddUploadCSV) {
      query.delete("UploadCSV");
      history.replace({ search: query.toString() });
    } else {
      query.set("UploadCSV", category || "");
      history.push({ search: query.toString() });
    }
    dispatch(setShowAddUploadCSV((prev: any) => !prev));
  };

  const onlyUnique = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
  };

  const Equipment = categorySelected == "67807598690173953";

  const goTo = (letter: ABC) => {
    // setPaddingView(false)
    if (sections[letter]) {
      sections[letter].ref.current?.scrollIntoView({behavior: 'smooth'});
    }
  };

  useEffect(() => {
    console.log(ItemData);
    console.log(sections);
  }, [sections]);

  const messageDisbled = (module:string) => {
    toast.info(module + ' module is coming soon')
  }

  return (
    <div className="d-flex flex-column mw-100 justify-content-center ps-1 my-sm-5 my-lg-0 h-100">
      <div className="d-flex flex-column ">
        <div className="row  align-items-center mt-3 mx-1 ">
          <div className="ps-1 col-auto">
            <ButtonIconText
              title={"Scan code"}
              custom={BarcodeIcon}
              customStyle={{ width: "15px", height: "12px", marginTop: "3.5%" }}
              onClick={() => setScanModal(true)}
            />
          </div>

          <div className={`p-0 col-auto m-0 ${Equipment ? "" : "d-none"} `}>
            <IconButton
              color="primary"
              custom={Kit}
              customStyles={{ width: "9.5px", height: "11px" }}
              onClick={props.toggleAddingkit}
              tooltip="Duplicate"
              outline
            />
          </div>
          <div
            className={`col-auto pe-0 ps-0${
              Equipment ? "me-1" : "ms-4 me-1 ps-1"
            }`}
            //style={categorySelected._id == "67807598690173953" ? {} : {}}
          >
            <DropdownCustom
              name="Sort by"
              options={[
                { name: "Name", action: () => setShortBy("name") },
                { name: "Brand", action: () => setShortBy("brand") },
                { name: "Category", action: () => setShortBy("category.name") },
              ]}
              type="text"
            />
          </div>
          <div className="p-0 col-auto">
            <IconButton
              color="primary"
              tooltip="Duplicate"
              custom={CopyIcon}
              customStyles={{ width: "9.5px", height: "11px" }}
              onClick={() => messageComming("Duplicate")}
              outline
            />
          </div>
          <div className={`p-0 col-auto ${Equipment && "mt-0"}`}>
            <DropdownCustom
              name=""
              options={[
                { name: "Inventory", action: () => messageComming('Inventory') },
                {
                  name: "Equipment",
                  action: () => SelectCategory("67807598690173953"),
                },
                { name: "Sales", action: () => messageComming('Sales')},
                {
                  name: "Service",
                  action: () => props.onAddService("9854945"),
                },
                { name: "Transport", action: () => messageComming('Transport') , disabled: true },
                {
                  name: "Upload CSV file",
                  action: () => toggleAddingCSV("28738239230"),
                },
              ]}
              type={{ type: "IconCustom", icon: PlusIcon }}
            />
          </div>
        </div>

        <Scanner
          isOpen={scanModal}
          setCode={setCoder}
          setStateModal={setScanModal}
        />

        <div className="input-icon ps-2 pe-2" style={{ marginTop: "3.5%" }}>
          <img
            src={SearchIcon}
            alt=""
            style={{
              width: "16px",
              height: "16px",
              marginTop: "1.4%",
              marginLeft: "4.4%",
            }}
          />
          <Input
            className="form-control py-2 typo-body text-primary"
            type="search"
            id="example-search-input"
            placeholder="Search"
            value={itemSearch}
            onChange={handleSearch}
            style={{ fontSize: "12px", height: "37px" }}
          />
        </div>

        <div className={`col-12  mt-2 mb-1 ${test && "d-none"}`}>
          <button
            className="btn btn-link typo-body p-0 float-end me-2"
            data-bs-toggle="dropdown"
            style={{ boxShadow: "none" }}
            onClick={() => {
              // dispatch(setCategoryFilter('any'))
              // dispatch(setCategorieActive(888876373900000))
            }}
          >
            ADVANCED SEARCH
          </button>
          {
            <AdvanceSearch
              setAdvanceCategory={setAdvanceCategory}
              setWords={setWords}
              setType={setType}
            />
          }
        </div>
      </div>

      <div className={`row p-0 contact-container w-100 ${Equipment && "mt-0"}`}>
        <div className="col  h-100 custom-scrollbar contact-list p-0 ps-2">
          <ItemListComponent
            LoadingItems={LoadingItems}
            ItemData={ItemData}
            sections={sections}
            results={results}
            setID={props.setID}
            showAssets={props.showAssets}
          />
        </div>

        <div className="list-linked overflow-y hide-scrollbar p-0 h-100">
          {[...Object.keys(sections), ...abc]
            .filter((item, index) => {
              return [...Object.keys(sections), ...abc].indexOf(item) === index;
            })
            .map((item: any, index: any) => {
              return (
                <p
                  onClick={goTo.bind(null, item)}
                  key={index}
                  className="m-0 p-0 typo-body text-primary"
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                    fontWeight: alphSelected == index ? "bold" : "",
                  }}
                >
                  <span
                    onClick={() => {
                      setAlphSelected(index);
                    }}
                  >
                    {item}
                  </span>
                </p>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ItemList;
