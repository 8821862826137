import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  CaseReducer,
  createReducer,
} from "@reduxjs/toolkit";
import factoryService from "src/services/factory";
import {
  JobsModel,
  ProjectModel,
  ProjectModelId,
} from "../../models/factoryModel";

interface data {
  id: any;
}

export const getAllProject = createAsyncThunk("factory/getAll", async () => {
  try {
    const data = await factoryService.GetAllProjects();
    return { data: data };
  } catch (error) {
    console.error(error);
  }
});

export const getProjectId = createAsyncThunk(
  "factory/getAll:id",
  async (id: ProjectModelId) => {
    try {
      const data: any = await factoryService.GetProjectsId(id);
      return data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const CreateProject = createAsyncThunk(
  "factory/createproject",
  async (project: ProjectModel) => {
    try {
      const data: any = await factoryService.CreateProject(project);
      console.log(data);
      return data;
    } catch (error: any) {
      console.error(error);
      console.log("eerrooorrrrrr", error.data);
      return error.data.statusCode.http;
    }
  }
);

export const DeleteProject = createAsyncThunk(
  "factory/deleteProject",
  async (id: any) => {
    try {
      const response = await factoryService.DeleteProject(id);
      return { data: response };
    } catch (error: any) {
      console.error(error);
    }
  }
);

export const updateProject = createAsyncThunk(
  "factory/project/id/status",
  async (project: any) => {
    try {
      const data: any = await factoryService.updateProject(project);
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  }
);

export const sendQuoteEmail = createAsyncThunk(
  "factory/email/send-quote",
  async (emailData: any) => {
    try {
      const data: any = await factoryService.SendQuoteMail(emailData.data, emailData.id);
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  }
);

const initialState = {
  loading: false,
  projectData: [] as any,
  projectDataId: null,
  ProjectResponse: null,
  updateProjectStatus: "" as any,
  IdQuoteEmail: "" as any
};

const factorySlice = createSlice({
  name: "factory",
  initialState,
  reducers: {
    cleanProjectData: (state) => {
      state.projectData = [] as any;
    },
    setIdQuoteEmail: (state , action) => {
      state.IdQuoteEmail = action.payload
    }
  },
  extraReducers: (builder) => {
    builder

      .addCase(updateProject.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateProject.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        state.loading = false;
        state.updateProjectStatus = action.payload;
      })
      .addCase(getProjectId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getProjectId.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getProjectId.fulfilled, (state, action) => {
        state.loading = false;
        state.projectDataId = action.payload;
      })
      .addCase(CreateProject.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(CreateProject.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(CreateProject.fulfilled, (state, action) => {
        state.loading = false;
        state.ProjectResponse = action.payload;
      })
      .addCase(getAllProject.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllProject.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllProject.fulfilled, (state, action) => {
        state.loading = false;
        state.projectData = [...action.payload?.data];
      });
  },
});

export const { cleanProjectData , setIdQuoteEmail } = factorySlice.actions;
export default factorySlice.reducer;
