interface Props {
    element?:any
}


const StockViewEmpty: React.FC<Props> = (props) => {

    return (
        <div style={{ display: 'flex', alignSelf: 'center' }}>
            <div style={{
                color: '#344469', justifyContent: 'center', height: '24em',
                marginTop: '32%', display: 'flex', alignItems: 'center',
                flexDirection: 'column'
            }}  >

                <div style={{
                    width: '122px',
                    height: '122px', borderRadius: '100%',
                    backgroundColor: '#DEE2E6', color: '#344469',
                    display: 'flex', justifyContent: 'center', alignItems: 'center'
                }}>
                    <svg fill='#344469' width={'89px'} height={'89px'} enable-background="new 0 0 32 32" id="Glyph" version="1.1" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" ><path d="M27.414,24.586l-5.077-5.077C23.386,17.928,24,16.035,24,14c0-5.514-4.486-10-10-10S4,8.486,4,14  s4.486,10,10,10c2.035,0,3.928-0.614,5.509-1.663l5.077,5.077c0.78,0.781,2.048,0.781,2.828,0  C28.195,26.633,28.195,25.367,27.414,24.586z M7,14c0-3.86,3.14-7,7-7s7,3.14,7,7s-3.14,7-7,7S7,17.86,7,14z" id="XMLID_223_" /></svg>
                </div>
                <h2 className='emptyselect' style={{ textAlign: 'center' }}>Select the {props.element?props.element:"item"} you want to review</h2>
            </div>
        </div>
    )
}

export default StockViewEmpty;