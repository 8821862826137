import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import InputSearch from "src/components/UI/forms/InputSearch";
import { useRequest } from "src/hooks/useRequest";
import { CleanRates, GetAllRateCards, GetAllRateForItem } from "src/store/stockpile/stockpileSlice";
import { RootState } from "src/store/store";


const Rates: React.FC = () => {
  const dispatch = useDispatch()
  const params = useParams<{ itemId: string; }>();

  //const RatesState: Array<any> = useSelector((state: RootState) => state.stockpileSlice.RatesForItem);
  const { data: RatesState } = useRequest<any>({ url: 'rate-cards/items/'+ params.itemId + '/all-rates'});
                              // useRequest<any>({ url: 'stockpile/items/' + params.itemId });


  const [switched, setswitched] = useState(false)
  const [ search , setSearch ] = useState('')

  const searchRate = (look:any, type:any) => {
      return RatesState?.filter((rate:any) => rate.name.includes(look)) 
  }

  const Rates = searchRate(search , 'client')

  useEffect(() => {
    console.log(RatesState);
    
  }, [RatesState])

  useEffect(() => {
  }, [params.itemId])

  useEffect(() => {
    return () => {
      dispatch(CleanRates())
    }
  } ,[])

  return (
    <div className="mt-4 me-4 p-3" style={{width: '106%', marginLeft:'-2.8%'}}>
      <div className="w-100 custom-scrollbar overflow-x">
        <table className="table table-borderless">
          <thead>
            <tr className="text-primary typo-body">
              <th scope="col">RATE CARD</th>
              <th scope="col">HOUR</th>
              <th scope="col">DAY</th>
              <th scope="col">WEEK</th>
              <th scope="col">SELL</th>
              <th scope="col">OVERTIME HOUR</th>
              <th scope="col">HOLIDAY HOUR</th>
              <th scope="col">HOLIDAY DAY</th>
              <th scope="col">HOLIDAY OVERTIME HOUR</th>
            </tr>
          </thead>

          <tbody>
            {
              RatesState?.map((rate: any) => {
                let rate_card_item: any = rate.rate_card_item.rates

                if (rate.blocked && rate.assigned_to == null) {
                  return (
                    <tr className="table-primary text-primary typo-body">
                      <th>Standard</th>
                      <td>${rate_card_item.hour.toLocaleString("en")}</td>
                      <td>${rate_card_item.daily.toLocaleString("en")}</td>
                      <td>${rate_card_item.week.toLocaleString("en")}</td>
                      <td>${rate_card_item.sale.toLocaleString("en")}</td>
                      <td>${rate_card_item.overtime_hour.toLocaleString("en")}</td>
                      <td>${rate_card_item.holiday_hour.toLocaleString("en")}</td>
                      <td>${rate_card_item.holiday_day.toLocaleString("en")}</td>
                      <td>${rate_card_item.holiday_overtime_hour.toLocaleString("en")}</td>
                    </tr>
                  )
                }

              })
            }
          </tbody>
        </table>
      </div>

      <div className="mt-4 border-top row px-3 py-2 align-items-center">
        <button onClick={() => { setswitched(false) }} className={`btn ${!switched ?'btn-primary':'btn-default'}  col-auto mx-2 btn-fitcontent`}>
          SYSTEM RATE CARDS
        </button>

        <button onClick={() => { setswitched(true) }} className={`btn ${switched ?'btn-primary':'btn-default'} col-auto  mx-2 btn-fitcontent`}>
          CLIENT RATE CARDS
        </button>

        <div className="col">
          <div className="input-icon">
            <InputSearch placeholder="Search" onChange={(value) => { setSearch(value) }}
            value={search} />
          </div>
        </div>
      </div>

      <div className="w-100 custom-scrollbar overflow-x ">
        <table className="table table-borderless">
          <thead>
            <tr className="text-primary typo-body">
              <th scope="col">RATE CARD</th>
              <th scope="col">HOUR</th>
              <th scope="col">DAY</th>
              <th scope="col">WEEK</th>
              <th scope="col">SELL</th>
              <th scope="col">OVERTIME HOUR</th>
              <th scope="col">HOLIDAY HOUR</th>
              <th scope="col">HOLIDAY DAY</th>
              <th scope="col">HOLIDAY OVERTIME HOUR</th>
            </tr>
          </thead>

          <tbody>
            {Rates?.map((rate: any, index: any) => {
              let rate_card_item: any = rate.rate_card_item.rates
              /// system rate cards
              if (!switched && !rate.blocked && rate.assigned_to == null) {
                return (
                  <tr
                    className={`${(index / 2) % 1 > -1 ? 'table-primary' : ''
                      } text-primary typo-body`}
                  >
                    <th>{rate.name}</th>
                    <td>${rate_card_item.hour.toLocaleString("en")}</td>
                    <td>${rate_card_item.daily.toLocaleString("en")}</td>
                    <td>${rate_card_item.week.toLocaleString("en")}</td>
                    <td>${rate_card_item.sale.toLocaleString("en")}</td>
                    <td>${rate_card_item.overtime_hour.toLocaleString("en")}</td>
                    <td>${rate_card_item.holiday_hour.toLocaleString("en")}</td>
                    <td>${rate_card_item.holiday_day.toLocaleString("en")}</td>
                    <td>${rate_card_item.holiday_overtime_hour.toLocaleString("en")}</td>
                  </tr>
                );
              }

              if (switched && rate.assigned_to != null) {
                return (
                  <tr
                    className={`${(index / 2) % 1 > 0 ? 'table-primary' : ''
                      } text-primary typo-body`}
                  >
                    <th>{rate.name}</th>
                    <td>${rate_card_item.hour.toLocaleString("en")}</td>
                    <td>${rate_card_item.daily.toLocaleString("en")}</td>
                    <td>${rate_card_item.week.toLocaleString("en")}</td>
                    <td>${rate_card_item.sale.toLocaleString("en")}</td>
                    <td>${rate_card_item.overtime_hour.toLocaleString("en")}</td>
                    <td>${rate_card_item.holiday_hour.toLocaleString("en")}</td>
                    <td>${rate_card_item.holiday_day.toLocaleString("en")}</td>
                    <td>${rate_card_item.holiday_overtime_hour.toLocaleString("en")}</td>
                  </tr>
                );
              }

            })}

            {/* <tr className="text-primary typo-body">
              <th>1</th>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
            </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Rates;


/**
 * los system rate cards blocked:true y assigned_to: null
 * los client rate cards assigned_to: !null
 * los standar rate_cards blocked:false y assigned_to: null
 */