import { useState } from "react";
import { Datepicker } from "@mobiscroll/react";
import { parseInputError } from "src/util";
import { style } from "@mui/system";

interface InputDateProps {
  className?: string;
  min?: any;
  max?: any;
  error?: any;
  placeholder?: string;
  value?: Date | string | number;
  onChange?(args: any, inst: any): void;
  select?: any;
  rangeHighlight?: boolean;
  showRangeLabels?: boolean;
  rangeStart?: any;
  rangeEnd?: any;
  touchUi?: any;
  height?: number;
}

const InputDate: React.FC<InputDateProps> = ({
  max,
  min,
  error,
  value,
  onChange,
  placeholder,
  select,
  rangeEnd,
  rangeStart,
  touchUi,
  height,
  className,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className={`input-group m-1 ml-2 mb-0 cursor-pointer ${className}`}>
        <Datepicker
          select={select}
          rangeEnd={rangeEnd}
          rangeStart={rangeStart}
          touchUi={touchUi}
          theme="ios"
          display="anchored"
          inputComponent="input"
          controls={["calendar"]}
          dateFormat="YYYY-MM-DD"
          inputProps={{
            className:
              "form-control inputDatePicker searchWords " +
              (error ? " is-invalid" : ""),
            style: { background: "#fff", height: `${height || 44.8}px`, color: "#6B8AB8"},
            placeholder: placeholder || "Please Select...",
            onClick: () => setOpen(true),
          }}
          min={min}
          max={max}
          value={value}
          isOpen={open}
          onChange={onChange}
          className="text-primary"
          onClose={() => setOpen(false)}
        ></Datepicker>
        <div className={"input-group-append  datepickerCustom "}>
          <span
            style={{
              height: `${height || 44.8}px`,
              borderRadius: "0px 3px 3px 0px",
              background: "#6b8ab8",
            }}
            onClick={() => setOpen(!open)}
            className="input-group-text cursor-pointer  datepickerCustomBtn"
          >
            <i className="bi bi-calendar" style={{ color: "#fff" }}></i>
          </span>
        </div>
      </div>

      {error && <div className="validation">{parseInputError(error)}</div>}
    </>
  );
};

export default InputDate;
