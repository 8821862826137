import { Modal } from 'reactstrap';
import { AxiosResponse } from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { FunctionComponent, RefObject, useCallback, useEffect, useMemo, useState } from 'react';

import { useToast } from 'src/hooks/useToast';
import useContacts from 'src/hooks/useContacts';
import { ICategory } from 'src/models/Category';
import EditIcon from 'src/assets/icons/edit.svg';
import { useRequest } from 'src/hooks/useRequest';
import TrashIcon from 'src/assets/icons/trash.svg';
import IconButton from '../../UI/buttons/IconButton';
import { ApiError, SoftDeleteStatus } from 'src/models';
import ProgressbarTitle from '../../UI/ProgressbarTitle';
import { BlackbookService } from 'src/services/blackbook';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import { ContactType, IContact } from 'src/models/ContactModel';
import fIcon from '../../../assets/icons/sidebar/factory-sidebar-icon.svg';
import { ContactTypeEx, IContactContactTypeEx } from 'src/util/ContactsFunctions';

import Notes from './tabs/Notes';

import FreelanceContactData from './tabs/Freelance/ContactData';
import FreelanceDetails from './tabs/Freelance/Details';
import EmergencyContacts from './tabs/Freelance/EmergencyContacts';

import ClientContactData from './tabs/Client/ContactData';

import OrganizationContactData from './tabs/Organization/ContactData';
import OrganizationDocuments from './tabs/Organization/Documents';
import TMContactData from './tabs/TeamMember/ContactData';
import TMDetails from './tabs/TeamMember/Details';
import ProspectContactData from './tabs/Prospect/ContactData';
import FinancialData from './tabs/FinancialData';
import Projects from './tabs/Projects';
import KPIRating from './tabs/KPIRating';

interface Props {
  isOpen: boolean;
  toggleIsOpen: () => void;
  contactViewCard: RefObject<HTMLDivElement>;
}

export interface TabsContent {
  title: string;
  type?: ContactType | 'all';
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Component: FunctionComponent<{ contact: IContact, extractor: IContactContactTypeEx }>;
}

const ContactView: React.FC<Props> = (props) => {
  const params = useParams<{ contactId: string; }>();
  const history = useHistory();

  const toast = useToast();
  /* const dispatch = useDispatch(); */

  const { mutate } = useContacts();
  const { data: contact, error, mutate: mutateContact } = useRequest<IContact>(params?.contactId ? { url: `blackbook/contacts/${params.contactId}` } : null);

  const [currentTab, setCurrentTab] = useState(0);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [restoreModalOpen, setRestoreModalOpen] = useState(false);

  const toggleOptionsState = () => setOptionsOpen((prev) => !prev);

  /* useEffect(() => {
    dispatch(layoutConfig.setModuleTitle('BLACKBOOK - CONTACT'));
  }, []); */

  const tabsContent = useMemo<TabsContent[]>(() => [
    { Component: FreelanceContactData, title: 'Contact Data', type: ContactType.FREELANCE },
    { Component: FreelanceDetails, title: 'Details', type: ContactType.FREELANCE },
    { Component: EmergencyContacts, title: 'Emergency Contacts', type: ContactType.FREELANCE },

    { Component: ProspectContactData, title: 'Contact Data', type: ContactType.PROSPECT },

    { Component: TMContactData, title: 'Contact Data', type: ContactType.TEAM_MEMBER },
    { Component: TMDetails, title: 'Details', type: ContactType.TEAM_MEMBER },
    { Component: EmergencyContacts, title: 'Emergency Contacts', type: ContactType.TEAM_MEMBER },

    { Component: ClientContactData, title: 'Contact Data', type: ContactType.CLIENT },
    { Component: FinancialData, title: 'Financial Data', type: ContactType.CLIENT },
    { Component: Projects, title: 'Projects', type: ContactType.CLIENT },
    { Component: KPIRating, title: 'KPI Rating', type: ContactType.CLIENT },

    { Component: OrganizationContactData, title: 'Contact Data', type: ContactType.ORGANIZATION },
    { Component: OrganizationDocuments, title: 'Corporate Documents', type: ContactType.ORGANIZATION },

    { Component: Notes, title: 'Notes', type: 'all' },
  ], []);

  const renderedTabs = useMemo(() => tabsContent.filter((tab) => (tab.type && tab.type === (contact?.category as ICategory)?.type) || tab.type === 'all'), [tabsContent, contact]);
  const contactExInfo = useMemo(() => !contact ? ContactTypeEx.FREELANCE : ContactTypeEx[contact?.type], [contact]);

  const title = useMemo(() => contact ? contactExInfo.getTitle(contact) : '', [contact, contactExInfo]);
  const image = useMemo(() => contact ? contactExInfo.getLogo(contact) : '', [contact, contactExInfo]);

  useEffect(() => {
    props.contactViewCard.current?.scrollTo({ top: 0, behavior: 'smooth' });
    setCurrentTab(0);
  }, [params, props.contactViewCard])

  const onDelete = useCallback((permanently: boolean) => {
    if (!contact?._id) return;

    BlackbookService.ArchiveContact(contact._id, permanently)
      .then(() => {
        mutate(undefined, true);
        setDeleteModalOpen(false);
        mutateContact(undefined, true);
        if (permanently) {
          history.replace('/blackbook');
        }
        return toast.success(permanently ? 'The contact has been successfully deleted.' : 'The contact has been successfully moved to the archive.');
      })
      .catch((err: AxiosResponse<ApiError>) => {
        return toast.error(err.data.message);
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact, mutate, mutateContact]);

  const onRestore = useCallback(() => {
    if (!contact?._id) return;

    BlackbookService.RestoreFromArchive(contact._id)
      .then((res) => {
        mutate(undefined, true);
        mutateContact(res, true);
        setRestoreModalOpen(false);
        return toast.success('Contact restored successfully.');
      })
      .catch((err: AxiosResponse<ApiError>) => {
        return toast.error(err.data.message);
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact, mutate, mutateContact])

  const handleUnimplementedBtn = useCallback(() => {
    toast.info({ message: 'This feature is not implemented yet.', timeout: 3000 });
  } , [toast]);

  return (
    <div className="d-flex flex-column h-100 px-2 ">
      {deleteModalOpen && (
        <ConfirmModal
          title="What do you want to do?"
          description={`You can delete this contact permanently or send it to the archive.${contact?.status.state === SoftDeleteStatus.DELETED ? ' (This contact is already in the archive.)' : ''}`}
          buttons={(
            <>
              <button
                className="btn btn-success text-white col-auto"
                onClick={() => setDeleteModalOpen(false)}
              >
                CANCEL
              </button>
              <button
                className="btn btn-danger text-white col-auto"
                onClick={() => onDelete(true)}
              >
                DELETE PERMANENTLY
              </button>
              {contact?.status.state === SoftDeleteStatus.ACTIVE && (
                <button
                  className="btn btn-primary text-white col-auto"
                  onClick={() => onDelete(false)}
                >
                  SEND TO ARCHIVE
                </button>
              )}
            </>
          )}
          onClose={() => setDeleteModalOpen(false)}
        />
      )}

      {restoreModalOpen && contact?.status.state === SoftDeleteStatus.DELETED && (
        <ConfirmModal
          cancelText="CANCEL"
          confirmText="RESTORE CONTACT"
          title="What do you want to do?"
          onClose={(confirmed?: boolean) => !confirmed ? setRestoreModalOpen(false) : onRestore()}
          description={`This contact is in the archive. You can restore and it'll appear in the main list again.`}
        />
      )}

      <Modal
        centered
        isOpen={imageModalOpen}
        toggle={() => setImageModalOpen(!imageModalOpen)}
      >
        {image && image !== '' ? (
          <img src={image} alt={`${title} Avatar`} className="w-100 h-100" draggable={false} />
        ) : (
          <span className="align-items-center d-flex fs-4 justify-content-center w-100 h-100 text-primary bg-contrast">
            {title[0]}
          </span>
        )}
      </Modal>

      {!params.contactId && (
        <div className="d-flex flex-column justify-content-center align-items-center h-100 text-muted">
          <i className="fs-3 bi bi-hand-index d-block"></i>
          <p className="h6">Please select a contact from the list.</p>
        </div>
      )}

      {!contact && !error && !!params.contactId && (
        <div className="d-flex justify-content-center align-items-center h-100">
          <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block"></i>
        </div>
      )}

      {!contact && error && (
        <div className="d-flex flex-column justify-content-center align-items-center h-100 text-muted">
          <i className="fs-3 bi bi-exclamation-triangle d-block"></i>
          <p className="h6">Error while loading the selected contact details.</p>
          <pre>
            {error.data ? error.data.message : error.statusText}
          </pre>
        </div>
      )}

      {contact && !error && (
        <>
          <div className="sticky-top bg-white">
            <header className="w-100 mt-3 d-flex justify-content-between align-items-center">
              <div className="col-auto d-flex gap-2">
                <i
                  onClick={props.toggleIsOpen}
                  className={`${
                    props.isOpen
                      ? 'bi bi-arrows-angle-contract'
                      : 'bi bi-arrows-angle-expand'
                  } text-primary h5 m-0 cursor-pointer`}
                ></i>
                {contact.status.state === SoftDeleteStatus.DELETED && <small className="text-muted">This contact is archived.</small>}
              </div>

              <div className="d-flex align-items-center">
                <ProgressbarTitle value={0.6} className="mx-2" />

                <IconButton
                  outline
                  color="primary"
                  icon="bi bi-share-fill"
                  tooltip="Share contact"
                  buttonProps={{
                    style: {
                      opacity: 0.6
                    }
                  }}
                  onClick={handleUnimplementedBtn}
                />

                <IconButton
                  outline
                  color="primary"
                  custom={EditIcon}
                  tooltip="Edit contact"
                  customStyles={{ height: 13, width: 13 }}
                  onClick={() => history.push(`/blackbook/edit/${contact._id}`)}
                />

                {contact.status.state === SoftDeleteStatus.DELETED && (
                  <IconButton
                    outline
                    color="primary"
                    tooltip="Restore contact"
                    icon="bi bi-arrow-clockwise"
                    onClick={() => setRestoreModalOpen(true)}
                  />
                )}

                <IconButton
                  outline
                  color="danger"
                  custom={TrashIcon}
                  tooltip="Delete contact"
                  customStyles={{ height: 13, width: 13 }}
                  onClick={() => setDeleteModalOpen(true)}
                />
              </div>
            </header>

            <div className="w-100 mt-3 row g-0 gap-0">
              <div className="col-2">
                  {image && image !== '' ? (
                    <img onClick={() => setImageModalOpen(!imageModalOpen)} src={image} alt={`${title} Avatar`} className="avatar-profile cursor-pointer" />
                  ) : (
                    <span className="align-items-center d-flex fs-4 justify-content-center avatar-profile text-primary bg-contrast">
                      {title[0]}
                    </span>
                  )}
              </div>
              <div className="col-10">
                <div className="d-flex justify-content-between">
                  <div>
                    <p className="m-0 text-black typo-body contact-title">{title}</p>
                    <p className="m-0 text-black typo-body contact-subtitle">
                      {contactExInfo.getSubtitle(contact)}
                    </p>
                  </div>

                  <p className="text-primary typo-body">Contact ID: {contact._id}</p>
                </div>

                <div className="d-flex align-items-center mt-2">
                  <div style={{ opacity: 0.6 }}>
                    <button className="btn btn-primary mx-1 typo-body text-white" onClick={handleUnimplementedBtn}>
                      PFP Message
                    </button>

                    <IconButton
                      color="primary"
                      icon="bi bi-envelope-fill"
                      tooltip="Send email"
                      onClick={handleUnimplementedBtn}
                    />

                    <IconButton
                      color="primary"
                      custom={fIcon}
                      tooltip="New project (f)"
                      onClick={handleUnimplementedBtn}
                    />

                    <IconButton
                      color="primary"
                      icon="bi bi-sticky"
                      tooltip="Add quick note (q)"
                      onClick={handleUnimplementedBtn}
                    />

                    {optionsOpen && (
                      <>
                        <IconButton
                          color="primary"
                          icon="bi bi-check-square-fill"
                          tooltip="New task (t)"
                          onClick={handleUnimplementedBtn}
                        />
                        <IconButton
                          color="primary"
                          icon="bi bi-cloud-fill"
                          tooltip="Go to CRM (c)"
                          onClick={handleUnimplementedBtn}
                        />
                        <IconButton
                          color="primary"
                          icon="bi bi-telephone-fill"
                          tooltip="VolP (v)"
                          onClick={handleUnimplementedBtn}
                        />
                      </>
                    )}
                  </div>

                  <IconButton
                    color="primary"
                    icon={`bi bi-chevron-${optionsOpen ? 'left' : 'right'}`}
                    tooltip="Close options"
                    onClick={toggleOptionsState}
                  />
                </div>
              </div>
            </div>

            {/* Tabs Title */}
            <ul className="nav nav-tabs mt-3">
              {renderedTabs.map((item, index) => {
                if (!contact.category || !item.type) return null;

                return (
                  <li
                    key={index}
                    className={"nav-item" + (currentTab === index ? ' cursor-default' : ' cursor-pointer')}
                    onClick={() => {
                      setCurrentTab(index)
                      props.contactViewCard.current?.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                  >
                    <p
                      className={`nav-link typo-body user-select-none ${
                        currentTab === index ? 'active' : ''
                      }`}
                      aria-current="page"
                    >
                      {item.title}
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>

          {/* Tabs */}
          {renderedTabs.map((Tab, index) => {
            const Component = Tab.Component;
            if (!contact.category || !Tab.type) return null;
            if (!tabsContent[index]) return null;
            if (index !== currentTab) return null;

            return (
              <Component extractor={contactExInfo} contact={contact} key={index} />
            )
          })}
        </>
      )}
    </div>
  );
};

export default ContactView;
