import FlatList from "flatlist-react";
import React, { useEffect, useState, Ref } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button, Row, Input } from "reactstrap";
import AdvanceSearch from "../../../../Stock/StockList/AdvanceSearch";
import ContactSection from "../../../../Stock/StockList/ContactSection";
import IconButton from "../../../../UI/buttons/IconButton";
import { RootState } from "../../../../../store/store";
import {
    getAllItems,
    cleanAssetData,
} from "../../../../../store/stockpile/stockpileSlice";
//import { getSections } from "../../../util/ItemsFunctions";
import { ABC, getSections } from "../../../../../util/index";
import Scanner from "../../../../Scanner/scanner";
import DropdownCustom from "../../../../UI/Dropdown/dropdown";
import ButtonIconText from "../../../../UI/buttons/ButtonIconText";
import ItemModel from "src/models/ItemModel";
import PlusIcon from "../../../../../assets/icons/add.svg";
import CopyIcon from "../../../../../assets/icons/copy.svg";
import BarcodeIcon from "../../../../../assets/icons/barcode.svg";
import SearchIcon from "../../../../../assets/icons/search.svg";
import Kit from "../../../../../assets/icons/kit.svg";
import FilterIcon from '../../../../../assets/icons/filter.svg'

import LetterSection from "src/components/DashboardLayout/MidCard/LetterSection";
import ListItem from "src/components/DashboardLayout/MidCard/ListItem";
import { useHistory } from "react-router-dom";
import {
    cleanAsset,
    setDataAsset,
    setIdItem,
} from "src/store/stockpile/selectAssetsSlice";
import useQuery from "src/hooks/useQuery";
import { setShowAddUploadCSV } from "src/store/stockpile/RecursosVisualeSlice";
import { setData } from "src/store/stockpile/createFormSlice";
import { useToast } from "src/hooks/useToast";
import {
    setCategorieActive,
    setCategoryFilter,
} from "src/store/stockpile/categorieSlice";
import ItemListComponent from "src/components/Molecules/ProjectListComponent";
import { setSelectedState } from "@progress/kendo-react-data-tools"
import CreateProjecForm from '../../createProyectForm/CreateProjectForm';
import FactoryModal from "../../../../Modals/FactoryModal";

interface props {
    data: string;
    onAddContact: any;
    onAddService: any;
    toggleAddingkit: any;
    view?: boolean;
    setView?: any;
    toggle?: any;
    activeControlView?: string;
    setActiveControlView?: any;
    selectJob: string | null;
    setSelectJob?: any;
    activeTask?: string | null;
    setActiveTask?: any;
    selectProject?: any;
    setSelectProject?: any;
    setViewOpenModalFactory?: any;
    closeModalJob: boolean;
    setCloseModalJob: any;
    viewOptions?: boolean;
    setViewOptions?: any;
}

const ProjectList: React.FC<any> = (props) => {
    const toast = useToast();
    const history = useHistory();
    const [itemSearch, setItemSearch] = useState<string>("");
    const [itemsFiltered, setItemsFiltered] = useState<any>([]);
    const [shotby, setShortBy] = useState<string>("name");
    const [code, setCoder] = useState<any>("");
    const [scanModal, setScanModal] = useState<boolean>(false);
    const [test, settest] = useState<boolean>(false);
    const query = useQuery();
    const [advcCategory, setAdvanceCategory] = useState<any>(false);
    const [words, setWords] = useState<any>("");
    const [type, setType] = useState<any>("");
    const [letterSelected, setStatusSelected] = useState<any>(false);
    const [viewOptions, setViewOptions] = useState(false);

    const { innerHeight } = window;

    const ProjectData = useSelector((state: RootState) => state.factorySlice);

    const { loading, projectData, ProjectResponse } = ProjectData;



    const [initialEmpty, setInitialEmpty] = useState<any>(false);
    const [alphSelected, setAlphSelected] = useState<any>();

    const ItemData = useSelector(
        (state: RootState) => state.stockpileSlice.ItemData
    );
    const LoadingItems = useSelector(
        (state: RootState) => state.stockpileSlice.LoadingItems
    );
    const categorySelected: any = useSelector(
        (state: RootState) => state.categorieSlice.categorySelectedFilter
    );
    const SubCategory: any = useSelector(
        (state: RootState) => state.categorieSlice.sub_categorySelectedFilter
    );

    const subCategorySelected: any = useSelector(
        (state: RootState) => state.categorieSlice.categorySelectedFilter
    );
    const ShowAddUploadCSV = useSelector(
        (state: RootState) => state.RecursosVisualeSlice.ShowAddUploadCSV
    );

    const ASSET_SELECTED = useSelector(
        (state: RootState) => state.selectAssetsSlice.data
    );

    const [indexList, setIndex] = useState("");

    const checkSelect = (indexone: any, indextwo: any) => {
        if (indexone == indextwo) {
            return true;
        }
        return false;
    };

    const dispatch = useDispatch();

    const abc: any = [
        "A",
        "B",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "Ñ",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
    ];

    const getData = () => {
        dispatch(getAllItems());
    };

    useEffect(() => {
        getData();
        cleanAssetData();
        // dispatch(setIdItem(''))
    }, []);


    useEffect(() => {
        dispatch(setDataAsset({}));
    }, []);



    const handleSearch = (e: any) => {
        setItemSearch(e.target.value);
    };

    const byCategoryForID = (item: any, category: any) => {
        if (category) {
            if (category == "any") {
                return true;
            } else {
                const result = item.category?._id == category ? true : false;

                return result;
            }
        } else return true;
    };

    const bySubCategoryForID = (item: any, sub_category: any) => {
        if (sub_category) {
            if (sub_category == "any") {
                return true;
            } else {
                const result =
                    item?.category?.sub_category?._id == sub_category ? true : false;

                return result;
            }
        } else return true;
    };

    const byType = (item: any, type: any) => {
        if (type) {
            if (type == 0) {
                return item.internal_assets > 0;
            }
            if (type == 1) {
                return item.external_assets > 0;
            }
        } else return item;
    };

    const byWordsName = (item: any, word: any) => {
        if (word) {
            return item.name.toLowerCase().includes(word.toLowerCase());
        } else return item;
    };

    const byWordsDesc = (item: any, word: any) => {
        if (word) {
            return item.description.toLowerCase().includes(word.toLowerCase());
        }
    };

    const bySearch = (item: any, search: any) => {
        if (search) {
            return item.name.toLowerCase().includes(search.toLowerCase());
        } else return item;
    };
    const FWords = (item: any, words: any) => {
        if (words) {
            return item.name.toLowerCase().includes(words.toLowerCase());
        } else return item;

        /* if (words.length > 0) {
                 const mp = words?.reduce((acc: Array<object>, word: any) => {
                     return acc.concat(items
                         .filter((item: any) => byWordsName(item, word))
                         .filter((item: any) => byWordsDesc(item, word))
                     )
                 }, [])
                 return mp
             } else return items*/
    };

    const filteredList = (
        items: any,
        search: any,
        cod: any,
        category: any,
        subCategory: any,
        AdvanceCategory: any,
        type: any,
        words: any
    ) => {
        const t = items
        /*   .filter((item: any) => byCategoryForID(item, category))
           .filter((asset: any) => bySearch(asset, cod))
           .filter((item: any) => bySearch(item, search))
           .filter((item: any) => byCategoryForID(item, AdvanceCategory))
           .filter((item: any) => byType(item, type))
           .filter((item: any) => bySubCategoryForID(item, subCategory))
           .filter((item: any) => FWords(item, words)); */

        return t;
    };

    const messageComming = (message: any) => {
        toast.info(message + " is coming soon!");
    };
    //  const sections = getSections(FWords(filteredList(ItemData, itemSearch, code, categorySelected, advcCategory, type, words), words), 'name')
    const sections = getSections<ItemModel, HTMLDivElement>(
        [
            ...filteredList(
                projectData,
                itemSearch,
                code,
                categorySelected,
                SubCategory,
                advcCategory,
                type,
                words
            ),
        ] || [],
        (item) => shotby
    );

    const results = filteredList(
        projectData,
        itemSearch,
        code,
        categorySelected,
        SubCategory,
        advcCategory,
        type,
        words
    );

    const SelectCategory = (category: any) => {
        props.setTypeForm(category);
        props.onAddContact(category);
    };

    const toggleAddingCSV: any = (category: any) => {
        if (ShowAddUploadCSV) {
            query.delete("UploadCSV");
            history.replace({ search: query.toString() });
        } else {
            query.set("UploadCSV", category || "");
            history.push({ search: query.toString() });
        }
        dispatch(setShowAddUploadCSV((prev: any) => !prev));
    };

    const onlyUnique = (value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
    };

    const Equipment = categorySelected == "67807598690173953";

    const goTo = (letter: ABC) => {
        // setPaddingView(false)
        if (sections[letter]) {
            sections[letter].ref.current?.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const [closeModalJob, setCloseModalJob] = useState(false);


    const openModalCreate = () => {
        setOpenModal(true);
        setCloseModalJob(true);
        setViewOptions(true);
    };

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectJob, setSelectJob] = useState<any>(null);
    const [selectProject, setSelectProject] = useState<any>(null);
    const [activeTask, setActiveTask] = useState<any>(null);

    const toggleFilt = () => {
        props.setFilterModal(!props.filterModal)
    }


    return (
        <div className="d-flex flex-column  justify-content-center ps-0 my-sm-5 my-lg-0 h-100"
            style={{ minWidth: '100%', maxWidth: '100%' }} >
            <div className="d-flex flex-column p-1" >
                <div className={`row  align-items-center ${letterSelected ? 'mt-3 pt-0' : 'mt-3'} mx-1 pe-4 `}
                    style={letterSelected ? { marginTop: '200px' } : {}}
                >

                    <div className="col-6">

                    </div>
                    <div className="col-2 ms-4 me-1">
                        <IconButton
                            color="primary"
                            custom={FilterIcon}
                            customStyles={{ width: "9.5px", height: "11px" }}
                            onClick={toggleFilt}
                            tooltip="Duplicate"
                            outline
                        />
                    </div>

                    <div className={`p-0 col-2 me-2 ${Equipment && "mt-0"}`}>
                        <button className="btn btn-primary" onClick={openModalCreate} style={{ width: '89px', height: '29px' }}>
                            Create
                        </button>
                    </div>
                </div>

                <Scanner
                    isOpen={scanModal}
                    setCode={setCoder}
                    setStateModal={setScanModal}
                />

                <div className="input-icon ps-2 pe-2" style={{ marginTop: "3.5%" }}>
                    <img
                        src={SearchIcon}
                        alt=""
                        style={{
                            width: "16px",
                            height: "16px",
                            marginTop: "1.4%",
                            marginLeft: "4.4%",
                        }}
                    />
                    <Input
                        className="form-control py-2 typo-body text-primary"
                        type="search"
                        id="example-search-input"
                        placeholder="Search"
                        value={itemSearch}
                        onChange={handleSearch}
                        style={{ fontSize: "12px", height: "37px" }}
                    />
                </div>

                <div className={`col-12  mt-2 mb-1 ${test && "d-none"}`}>


                </div>
            </div>
            <div className="mb-0 mt-2 ps-3" style={{
                background: '#F3F3F3', height: '30px', width: '100%', color: '#8898AA',
                fontSize: '19px', overflow: 'auto'
            }}>
                PROJECTS
            </div>
            <div className={`row p-0 contact-container w-100  ${Equipment && "mt-0"}`} style={{ overflow: 'hidden' }}>

                <div className="col  h-100 custom-scrollbar contact-list p-0 ps-2">

                    <ItemListComponent
                        LoadingItems={LoadingItems}
                        ItemData={projectData}
                        sections={sections}
                        results={results}
                        setID={props.setID}
                        showAssets={props.showAssets}
                    />
                </div>

                <div className="list-linked overflow-y hide-scrollbar p-0 h-100" >
                    {[...Object.keys(sections), ...abc]
                        .filter((item, index) => {
                            return [...Object.keys(sections), ...abc].indexOf(item) === index;
                        })
                        .map((item: any, index: any) => {
                            return (
                                <p
                                    onClick={goTo.bind(null, item)}
                                    key={index}
                                    className="m-0 p-0 typo-body text-primary"
                                    style={{
                                        fontSize: "12px",
                                        textAlign: "center",
                                        fontWeight: alphSelected == index ? "bold" : "",
                                    }}
                                >
                                    <span
                                        onClick={() => {
                                            setAlphSelected(index);
                                            setStatusSelected(true)
                                        }}
                                    >
                                        {item}
                                    </span>
                                </p>
                            );
                        })}
                </div>
            </div>

            <FactoryModal
                toggle={openModal}
                view={openModal}
                setView={() => props.setOpenModal(false)}
                activeControlView={props.activeControlView}
                setActiveControlView={props.setActiveControlView}
                selectJob={props.selectJob}
                setSelectJob={props.setSelectJob}
                activeTask={props.activeTask}
                setActiveTask={props.setActiveTask}
                selectProject={props.selectProject}
                setSelectProject={props.setSelectProject}
                closeModalJob={props.closeModalJob}
                setCloseModalJob={props.setCloseModalJob}
                viewOptions={viewOptions}
                setViewOptions={setViewOptions}
            />

        </div>
    );
};

export default ProjectList;
