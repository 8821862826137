import FlatList from "flatlist-react";
import LetterSection from "src/components/DashboardLayout/MidCard/LetterSection";
import ListItem from "src/components/DashboardLayout/MidCard/ListItem";
import ItemModel from "src/models/ItemModel";
import ContactItem from '../Factory/FactoryView/NewLayout/ProjectList/ContactItem';
import { ABC, getSections } from '../../util/index';

interface Props {
    ItemData: Array<any>,
    LoadingItems: any,
    sections: any,
    results: any,
    setID: any,
    showAssets: any
}


const ProjectListComponent: React.FC<Props> = (props) => {

    const ItemData = props.ItemData
    const LoadingItems = props.LoadingItems
    const sections = props.sections
    const results = props.results


    return (<div className="p-0 m-0 h-100" >
        {LoadingItems && (
            <div className="d-flex justify-content-center align-items-center h-100">
                <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block"></i>
            </div>
        )}



        {ItemData && ItemData.length > 0 && (
            <FlatList
                list={Object.keys(sections)}
                renderItem={(item: any, index: any) => {
                    if (!sections[item].items.length) return null;
                    if (!item) return 'without brand'
                    return (
                        <LetterSection
                            key={index}
                            letter={''}
                            ref={sections[item].ref}
                            styles={{display: 'none'}}
                            renderItems={() => (
                                <>
                                    {sections[item].items.map((Item: ItemModel, index: any) => {
                                        return (<ContactItem
                                            key={index}
                                            item={Item}
                                            onSelect={() => { }}
                                            setID={props.setID}
                                            showAssets={props.showAssets}
                                            index={index}

                                        />)
                                    })}
                                </>
                            )}
                        />
                    );

                }}
                renderWhenEmpty={() =>
                    <p></p>
                }

            />)}

        {results.length < 1 && ItemData.length > 0 && (
            <div className="d-flex flex-column justify-content-center align-items-center h-100 text-muted mx-5 text-center">
                <i className="fs-3 bi bi-back d-block"></i>
                <p className="h6">No items were found for the current filters.</p>
            </div>
        )}

        {ItemData.length < 1 && (
            <div className="d-flex flex-column justify-content-center align-items-center h-100 text-muted mx-5 text-center">
                <i className="fs-3 bi bi-back d-block"></i>
                <p className="h6">No items were found , must be create an new item.</p>
            </div>
        )}

    </div>)

}

export default ProjectListComponent